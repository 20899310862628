import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';
import { ReactComponent as SvgClose } from '../img/close.svg';
import { ReactComponent as SvgFiltros } from '../img/filtros.svg';
import FlechaAbajo from '@mui/icons-material/KeyboardArrowDownRounded';
import AodIcon from '@mui/icons-material/Aod';
import HeadphonesIcon from '@mui/icons-material/Headphones';

class BarraFiltros extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      categorias: [],
      ididit: false,
    };
    this.check = this.handleCheckClick.bind(this);
    this.remover = this.botonRemover.bind(this);
  }

  peticionAxios() {
    const url = process.env.REACT_APP_URL + 'filtros.php';
    axios.get(url
    ).then(response => response.data)
      .then((data) => {
        this.setState({ categorias: data });
      })
  }

  getFolderCats(slug, subcat) {
    const url = process.env.REACT_APP_URL + 'getfoldercats.php';
    axios.get(url, {
      params: {
        slug: slug,
        subcat: subcat
      }
    }).then(response => response.data)
      .then((data) => {
        this.props.overwriteCats(data);
      })
  }

  componentDidMount() {
    const { history, location } = this.props;
    const thisRedux = this.props.searchState.search;
    const search = location.search;
    const loc = location.pathname.split('/');
    const folder = loc[1].toString();
    const params = new URLSearchParams(search);
    const getebooks = params.get('ebooks');
    const getaudiolibros = params.get('audiolibros');
    const getparametros = params.get('parametros');
    const getcats = '[' + params.get('cats') + ']';
    const stateCats = JSON.stringify(thisRedux.catIds);
    if ((getcats !== stateCats || getparametros !== thisRedux.parametros || thisRedux.ebooks !== getebooks || thisRedux.audiolibros !== getaudiolibros) && (folder === 'busqueda')) {
      this.props.overwriteCats(JSON.parse(getcats));
      this.props.sendParameters(getparametros);
      this.props.setEbooks(getebooks);
      this.props.setAudiolibros(getaudiolibros);
      //console.log('barra mount 1');
    } else if (folder === 'edades' || folder === 'colecciones' || folder === 'planes') {
      const slug = loc[2].toString();
      let subcat = false;
      if (loc.length > 3) {
        subcat = loc[3].toString();
      }
      this.getFolderCats(slug, subcat);
      //console.log('barra mount 2');
    } else {
      this.peticionAxios();
      //console.log('barra mount 3', this.state.categorias);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, location } = this.props;
    const prevRedux = prevProps.searchState.search;
    const thisRedux = this.props.searchState.search;
    const { ididit } = this.state;
    const search = location.search;
    const params = new URLSearchParams(search);
    const newSearch = params;
    const loc = location.pathname.split('/');
    const folder = loc[1].toString();
    const getebooks = params.get('ebooks');
    const getparametros = params.get('parametros');
    const getcats = '[' + params.get('cats') + ']';
    const audiolibros = params.get('audiolibros');
    if ((thisRedux.catIds !== prevRedux.catIds || thisRedux.parametros !== prevRedux.parametros || thisRedux.ebooks !== prevRedux.ebooks || thisRedux.audiolibros !== prevRedux.audiolibros)) {
      params.set("ebooks", thisRedux.ebooks);
      params.set("audiolibros", thisRedux.audiolibros);
      params.set("cats", thisRedux.catIds);
      params.set("parametros", thisRedux.parametros);

      if (ididit) {
        if (folder !== 'busqueda' && folder !== 'administrador') {
          history.push(`/busqueda?${newSearch}`);
        } else {
          history.push(`${location.pathname}?${newSearch}`);
        }
        this.setState({ ididit: false });
      }
      this.peticionAxios();

      //console.log('barra update');
    }
    /*if (folder === 'edades' || folder === 'colecciones' || folder === 'planes') {
      const slug = loc[2].toString();
      this.getFolderCats(slug);
    }*/
  }

  handleCheckClick(e, categoria) {
    const element = e.target;
    const checked = element.checked;
    this.setState({ ididit: true });
    if (checked) {
      this.props.addCat(categoria);
    } else {
      this.props.removeCat(categoria);
    }
    const parent = element.closest("ul.dropdown-menu");
    const grandparent = document.querySelector("#barrafiltros .dropdown-toggle.show");
    parent.classList.remove("show");
    grandparent.classList.remove("show");
  }

  handleEbooks() {
    const { ebooks } = this.props.searchState.search;
    this.setState({ ididit: true });
    if (ebooks === true) {
      this.props.setEbooks(false);
    } else {
      this.props.setEbooks(true);
    }
  }

  handleAudiolibros() {
    const { audiolibros } = this.props.searchState.search;
    this.setState({ ididit: true });
    if (audiolibros === true) {
      this.props.setAudiolibros(false);
    } else {
      this.props.setAudiolibros(true);
    }
  }

  botonRemover(e, categoria) {
    this.props.removeCat(categoria);
    this.setState({ ididit: true });
  }


  render() {
    const { ebooks, audiolibros, conteo, catIds } = this.props.searchState.search;
    const { location } = this.props;
    var loc = location.pathname.split('/');
    var folder = loc[1].toString();


    return (conteo && catIds) ? (
      <div id="barrafiltros">
        <div className="container-fluid filtros">
          <nav className="navbar navbar-expand-lg navbar-light">
            <span className="encabezado">
              <span className="titulo">Filtros</span>
              <span className={"movil" + ((folder !== "") ? ('') : (' home'))}><div><SvgFiltros /> Filtrar por </div><button className="cerrar2" data-bs-toggle="collapse" data-bs-target="#colapseFiltros" type="button"><SvgClose /></button></span>
            </span>
            <ul key='principal' className="navbar-nav me-auto mb-2 mb-lg-0">
              {this.state.categorias.map((categorias) => (
                categorias.categorias.map((categoria) => (
                  <li key={'cat' + categoria.id} className="nav-item dropdown">
                    <div className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{categoria.nombre} <FlechaAbajo /></div>
                    <ul key={'ul' + categoria.id} className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {categoria.menus.map((menu) => (
                        (conteo[parseInt(categoria.id)]) ?
                          ((conteo[parseInt(categoria.id)][parseInt(menu.taxid)]) ? (
                            <li key={'tax' + menu.taxid}>

                              <div className="dropdown-item">
                                <div className="form-check ">

                                  <label className={"btn categos form-check-label " + ((catIds.includes(parseInt(menu.taxid))) ? ('activo') : ('inactivo'))} htmlFor={"categocheck" + menu.taxid}>
                                    <input className="form-check-input" type="checkbox" value={menu.taxid} id={"categocheck" + menu.taxid} onChange={e => { this.check(e, parseInt(menu.taxid)) }} checked={catIds.includes(parseInt(menu.taxid))} />
                                    {menu.taxnombre.replace('<br />', ': ')}
                                    <span> ({conteo[parseInt(categoria.id)][parseInt(menu.taxid)]})</span>

                                  </label>

                                </div>
                              </div>
                            </li>
                          ) : (null)) : (null)
                      ))
                      }
                    </ul>
                  </li>
                ))
              )
              )
              }
              <li key='ebooks'><button onClick={() => this.handleEbooks()} className={(ebooks === 'true' || ebooks === true) ? ('active') : ('')}><AodIcon />Libros electrónicos</button></li>
              <li key='audiolibros'><button onClick={() => this.handleAudiolibros()} className={(audiolibros === 'true' || audiolibros === true) ? ('active') : ('')}><HeadphonesIcon />Audiolibros</button></li>
              <li key='reset'><button onClick={() => this.props.resetSearch()}>Borrar</button></li>
            </ul>
          </nav>
        </div>
        <div className="seleccionados">
          {catIds.map((catid) => (
            this.state.categorias.map((categorias) => (
              categorias.categorias.map((categoria) => (
                categoria.menus.map((menu) => (
                  <Fragment key={menu.taxid}>
                    {(catid == menu.taxid) ? (<button className={catid} type="button" onClick={(event) => this.remover(event, menu.taxid)}><span>{menu.taxnombre.replace('<br />', ': ')}</span> <SvgClose /></button>) : (null)}
                  </Fragment>
                ))))))
          )
          )}
        </div>
      </div>
    ) : (null)
  }
}

const mapStateToProps = state => ({
  searchState: state
})

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    addCat: (catId) => dispatch({ type: 'ADD_CAT', chosenId: catId }),
    removeCat: (catId) => dispatch({ type: 'REMOVE_CAT', chosenId: catId }),
    sendParameters: (parametros) => dispatch({ type: 'SEND_PARAMETERS', parametros: parametros }),
    overwriteCats: (cat) => dispatch({ type: 'OVERWRITE_CATS', cat: cat }),
    setEbooks: (ebooks) => dispatch({ type: 'SET_EBOOKS', ebooks: ebooks }),
    setAudiolibros: (audiolibros) => dispatch({ type: 'SET_AUDIOLIBROS', audiolibros: audiolibros }),
    resetSearch: () => dispatch({ type: 'RESET_SEARCH' }),
    dispatch,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BarraFiltros));