import React, { Component } from "react";
import PostForm from "../components/PostForm";
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import Pager from "../components/Pager";
import { ToastContainer, toast } from 'react-toastify';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import DoneIcon from '@mui/icons-material/Done';
import ArticuloContainer from "../components/ArticuloContainer";
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

class AdminBlog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activo: 1,
            lista_articulos: [],
            pagina: 1,
            articleId: false,
            totales: 1,
        };

    }
    peticionAxios() {
        const { pagina } = this.state;
        let formData = new FormData();
        formData.append('pagina', pagina);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'listarArticulosAdmin.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ lista_articulos: data.articles, totales: data.totalRecords });

            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }
    showConfirmationModal(idarticulo) {
        if (window.confirm("¿Está seguro de querer borrar el artículo?")) {
            this.borrararticulo(idarticulo);
        }
    }
    borrararticulo(idarticulo) {
        let formData = new FormData();
        formData.append('id', idarticulo);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'borrararticulo.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.peticionAxios();
            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }
    publicarArticulo(idarticulo) {
        let formData = new FormData();
        formData.append('id', idarticulo);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'publicararticulo.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.peticionAxios();
            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }
    prioridadArticulo(idarticulo) {
        let formData = new FormData();
        formData.append('id', idarticulo);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'prioridadarticulo.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.peticionAxios();
            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }
    componentDidMount() {
        this.peticionAxios();
    }
    componentDidUpdate(prevProps, prevState) {
        const { activo, articleId, pagina, totales, lista_articulos } = this.state;
        if (prevState.activo !== activo && activo === 1) {
            this.peticionAxios();
            this.setState({ articleId: false });
        }
        if (prevState.pagina !== pagina) {
            this.peticionAxios();
        }
    }

    seccionActiva(activo) {
        this.setState({ activo: activo });
    }
    handleArticleSave = (articleData) => {
        //console.log("Article saved:", articleData);
        const { articleId } = this.state;
        if (articleId === false) {
            toast.success("Artículo salvado", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
                hideProgressBar: false
            });
        } else {
            toast.success("Artículo editado", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
                hideProgressBar: false
            });
        }
        this.setState({ activo: 1, articleId: false });
    };
    onPageChange = (pagina) => {
        this.setState({ pagina: pagina });
    };
    render() {
        const { activo, articleId, pagina, totales, lista_articulos } = this.state;
        return (
            <div className="container-xxl perfil">
                <ToastContainer />
                <div className="row fondo_gris">
                    <div className="col px-2">
                        <h1>Blog</h1>
                    </div>
                </div>
                <div className="row"><div className="col px-2"><button className={"tab" + ((activo === 1) ? (' active') : (""))} onClick={() => this.seccionActiva(1)}>Lista de Artículos</button><button className={"tab" + ((activo === 2) ? (' active') : (""))} onClick={() => this.seccionActiva(2)}>Crear/Editar artículo</button></div></div>
                {(activo === 1) ? (
                    <div className="row">
                        <div className="col px-2">
                            <h2>Artículos</h2>
                            <div id="listaarticulos">
                                <ul>
                                    <li><span></span><span><span>Editar</span> <span>Previsualizar</span> <span>Publicado</span> <span>En portada</span>  <span>Eliminar</span></span></li>
                                    {lista_articulos.map((articulo) => (
                                        <li key={articulo.id}>
                                            <span><span>{articulo.id}</span><span>{articulo.titulo}</span><span>{articulo.autor}</span><span>{articulo.creacion}</span></span>
                                            <span>
                                                <button onClick={() => this.setState({ activo: 2, articleId: articulo.id })}><EditIcon /></button>
                                                <button type="button" data-bs-toggle="modal" data-bs-target="#previewModal" onClick={() => this.setState({ articleId: articulo.id })}><PreviewIcon /></button>
                                                <button onClick={() => this.publicarArticulo(articulo.id)}>{(articulo.publicar === "0")?(<UnpublishedIcon />):(<DoneIcon />)}</button>
                                                <button onClick={() => this.prioridadArticulo(articulo.id)}>{(articulo.prioridad === "0")?(<VisibilityOffIcon />):(<ViewCarouselIcon />)}</button>
                                                <button onClick={() => this.showConfirmationModal(articulo.id)}><DeleteIcon /></button>
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                <Pager page={pagina} totales={totales} onPageChange={this.onPageChange} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col px-2">
                            <h2>{(articleId) ? (<span> {'Editando id: ' + articleId}</span>) : ('Nuevo artículo')}</h2>
                            <PostForm articleid={articleId} onSave={this.handleArticleSave} />
                        </div>
                    </div>
                )
                }

                <div className="modal fade" id="previewModal" tabIndex="-1" aria-labelledby="modallabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button id="closepreview" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <ArticuloContainer articleid={articleId} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default AdminBlog;