import React, { Component, Fragment, setState, useContext } from "react";
import { NavLink, Link, withRouter, useLocation } from "react-router-dom";
import { connect } from 'react-redux';



class NavAdmin extends Component {

  render() {

    var initllave = 1;
    function llave() {
      initllave++;
      return initllave;
    }

    return (
      <Fragment>
        <li key={'nav' + llave()} className="divisor"></li>
        <li key={'nav' + llave()} className="nav-item">
          <NavLink activeClassName="activo" className="nav-link admin" exact to="/administrador" >Colecciones</NavLink>
        </li>
        <li key={'nav' + llave()} className="nav-item">
          <NavLink activeClassName="activo" className="nav-link admin" exact to="/administrador/blog" >Blog</NavLink>
        </li>
        <li key={'nav' + llave()} className="nav-item">
          <NavLink activeClassName="activo" className="nav-link admin" exact to="/administrador/publicaciones" >Publicaciones</NavLink>
        </li>
        <li key={'nav' + llave()} className="nav-item">
          <NavLink activeClassName="activo" className="nav-link admin" to="/administrador/usuarios" >Usuarios</NavLink>
        </li>
        <li key={'nav' + llave()} className="nav-item">
          <NavLink activeClassName="activo" className="nav-link admin" to="/administrador/podcasts" >Podcast</NavLink>
        </li>
        <li key={'nav' + llave()} className="nav-item">
          <NavLink activeClassName="activo" className="nav-link admin" exact to="/administrador/config" >Configuración</NavLink>
        </li>
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  loggedstate: state
})


const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavAdmin));