import React, { Component } from "react";
import SpotifyFrame from "../components/SpotifyFrame";
import axios from 'axios';

class Podcast extends Component {
  constructor(props) {
    super(props)
    this.state = {
      podcasts: [],
    }
  }

  componentDidMount(){
    this.peticionAxios();
  }

  peticionAxios() {
    const url = process.env.REACT_APP_URL + 'podcasts.php';
    axios.get(url
    ).then(response => response.data)
      .then((data) => {
        this.setState({ podcasts: data })
        console.log(this.state.podcasts);
      })
  }
  render() {
    return (

      <div className="container-xxl">
        <div className="row podcasts g-2">
          {this.state.podcasts.map((podcasts) => (
            podcasts.map((podcast) => (
            <div className="col col-12 col-sm-12 col-md-6">
              <SpotifyFrame  iframe={podcast.liga} sap={podcast.sap} />
            </div>
            ))
          ))}
      </div>
      </div>
    );
  }
}

export default Podcast;