import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "./Pager.css";

const Pager = ({ page, totales, onPageChange }) => {
  const pagerRef = useRef(null);
  const [availableWidth, setAvailableWidth] = useState(0);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(totales);

  useEffect(() => {
    const handleResize = () => {
      if (pagerRef.current) {
        const width = pagerRef.current.offsetWidth;
        setAvailableWidth(width);
      }
    };

    // Initial width calculation
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [totales]);

  useEffect(() => {
    calculateAdjacentPages(availableWidth);
  }, [page, totales, availableWidth]);

  const calculateAdjacentPages = (width) => {
    const buttonWidth = 70; // Adjust this value to fit your button size
    const maxAdjacentPages = Math.floor(width / buttonWidth) - 5;

    let newStartPage = Math.max(page - Math.floor(maxAdjacentPages / 2), 1);
    let newEndPage = Math.min(newStartPage + maxAdjacentPages - 1, totales);

    // Adjust newStartPage and newEndPage to ensure the current page is at the middle
    if (newEndPage - newStartPage < maxAdjacentPages - 1) {
      if (newStartPage === 1) {
        newEndPage = Math.min(newStartPage + maxAdjacentPages - 1, totales);
      } else {
        newStartPage = Math.max(newEndPage - maxAdjacentPages + 1, 1);
      }
    }
    setStartPage(newStartPage);
    setEndPage(newEndPage);
  };

  const goToFirstPage = () => {
    onPageChange(1);
  };

  const goToLastPage = () => {
    onPageChange(totales);
  };

  const goToPrevious20Pages = () => {
    if (page > 20) {
      onPageChange(page - 20);
    } else {
      onPageChange(1);
    }
  };

  const goToNext20Pages = () => {
    if (page + 20 <= totales) {
      onPageChange(page + 20);
    } else {
      onPageChange(totales);
    }
  };

  const goToPreviousPage = () => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  };

  const goToNextPage = () => {
    if (page < totales) {
      onPageChange(page + 1);
    }
  };

  const Button = ({ label }) => {
    const isActive = page === label;
    const className = isActive ? "active" : "";

    const handleClick = () => {
      onPageChange(label);
    };

    return (
      <button className={className} onClick={handleClick}>
        {label}
      </button>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (pagerRef.current) {
        const width = pagerRef.current.offsetWidth;
        setAvailableWidth(width);
        calculateAdjacentPages(width);
      }
    };

    // Initial width calculation
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    calculateAdjacentPages(availableWidth);
  }, [page, totales, availableWidth]);

  if (!totales || totales < 2) {
    return ''; // Render blank content
  } else {
    return (
      <div className="pager" ref={pagerRef}>
        <button onClick={goToFirstPage}>
          <FirstPageIcon />
        </button>
        <button
          className={page <= 20 ? "inactive" : ""}
          onClick={goToPrevious20Pages}
        >
          -20<NavigateBeforeIcon />
        </button>
        <button
          className={page === 1 ? "inactive" : ""}
          onClick={goToPreviousPage}
        >
          <NavigateBeforeIcon />
        </button>

        {/* Render adjacent pages */}
        {startPage > 2 && <Button label={startPage - 2} />}
        {startPage > 1 && <Button label={startPage - 1} />}
        {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
          <Button key={startPage + index} label={startPage + index} />
        ))}
        {endPage < totales && <Button label={endPage + 1} />}
        {endPage < totales - 1 && <Button label={endPage + 2} />}

        <button
          className={page === totales ? "inactive" : ""}
          onClick={goToNextPage}
        >
          <NavigateNextIcon />
        </button>
        <button
          className={page + 20 > totales ? "inactive" : ""}
          onClick={goToNext20Pages}
        >
          <NavigateNextIcon />+20
        </button>
        <button onClick={goToLastPage}>
          <LastPageIcon />
        </button>
      </div>
    );
  };
}

Pager.propTypes = {
  page: PropTypes.number.isRequired,
  totales: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pager;
