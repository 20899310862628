import React, { Component } from "react";
import axios from "axios";
//import ReCaptchaV2 from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { FormErrors } from './FormErrors.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class FormaInicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      email: '',
      password: '',
      formErrors: { email: '', password: '' },
      emailValid: false,
      passwordValid: false,
      formValid: false
    };
  }
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      { [name]: value },
      () => { this.validateField(name, value) });
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Necesita ingresar un correo electrónico válido';
        break;
      case 'password':
        passwordValid = value.length >= 8;
        fieldValidationErrors.password = passwordValid ? '' : 'La contraseña debe ser mayor a 8 caracteres';
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
      passwordValid: passwordValid
    }, this.validateForm);
  }
  validateForm() {
    this.setState({ formValid: this.state.emailValid && this.state.passwordValid });
  }
  errorClass(error) {
    return (error.length === 0 ? '' : 'has-error');
  }
  handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    let formData = new FormData();
    formData.append('email', this.state.email)
    formData.append('password', this.state.password)

    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'login.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {

        this.setState({ success: data[0].resultados });
        if (this.state.success === 2) {
          //document.getElementById("closeinicio").click();
          this.props.entrar(data[0].userid, data[0].email, data[0].rol);
          this.notify();
        }
      })
      .catch(function (response) {
        //handle error

        console.log(response)
      });
  }

  notify() {
    toast.success("Bienvenido", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
      hideProgressBar: true
    });

    setTimeout(() => (document.getElementById("closeinicio").click()), 1500);

  }

  render() {
    return (
      <div className="modal fade" id="inicioModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <button id="closeinicio" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
        <ToastContainer />
        <form onSubmit={e => this.handleFormSubmit(e)} autoComplete='on' autoCorrect="off" autoCapitalize="none">
          <h1>Iniciar sesión</h1>
          <p>Bienvenido a Literaturas SM.</p>
          <div className="mb-3">
            <input className={`form-control ${this.errorClass(this.state.formErrors.email)}`} name="email" value={this.state.email} onChange={(event) => this.handleUserInput(event)} type="email" placeholder="Correo electrónico" /> {this.state.success === 0 && (<p className="has-error">Este correo no está registrado</p>)}
            <input className={`form-control ${this.errorClass(this.state.formErrors.password)}`} name="password" type="password" value={this.state.password} onChange={(event) => this.handleUserInput(event)} placeholder="Contraseña" /> {this.state.success === 1 && (<p className="has-error">Contraseña incorrecta</p>)}
          </div>
          <div className="d-flex justify-content-end"><button type="button" className="olvidaste" data-bs-toggle="modal" data-bs-target="#recuperarModal">¿OLVIDASTE TU CONTRASEÑA?</button></div>
          <button className="rojo" type="submit" disabled={!this.state.formValid}>ENTRAR</button>
          <FormErrors formErrors={this.state.formErrors} />
        </form>
        </div>
                <div className="modal-footer">
                  <button type="button" data-bs-toggle="modal" data-bs-target="#registroModal">¿NO TIENES CUENTA? REGÍSTRATE</button>
                </div>
              </div>
            </div>
          </div>
    )
  }
}

const mapStateToProps = state => ({
  loggedstate: state
})


const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    entrar: (userid,email,rol) => dispatch({
      type: 'LOG_IN',
      userid: userid,
      email: email,
      rol: rol
    }),
    salir: () => dispatch({ type: 'LOG_OUT' }),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormaInicio);