import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Carousel from 'react-elastic-carousel';
import { ReactComponent as SvgFlecha1 } from '../img/flecha1.svg';
import { Link } from "react-router-dom";
import Novedades from "../components/NovedadesHome";
import SpotifyFrame from "../components/SpotifyFrame";
import AodIcon from '@mui/icons-material/Aod';
import HeadphonesIcon from '@mui/icons-material/Headphones';

class Carrusel2 extends Component {

  constructor(props) {
    super(props)
    this.breakPoints = [
      { width: 1, itemsToShow: 2.5, showArrows: false },
      { width: 550, itemsToShow: 2.5, showArrows: false },
      { width: 992, itemsToShow: 4, showArrows: false },
      { width: 1150, itemsToShow: 4 },
      { width: 1450, itemsToShow: 5 },
      { width: 1750, itemsToShow: 5 },
    ];
    this.ira = this.ira.bind(this);
  }

  ira(cat, texto) {
    this.props.overwriteCats(cat);
    document.getElementById("textocatego").innerHTML = texto.replace(' años', '');
    const menuToggle = document.querySelector('#hamburguesita[aria-expanded="true"]');
    if (menuToggle) {
      menuToggle.click();
    }
  }

  render() {
    var slugcat = this.props.slugcat;
    var carril = this.props.carril;
    var categoria = this.props.categoria;
    var plan = this.props.plan;
    var idcat;
    var txtplan = plan.charAt(0).toUpperCase() + plan.slice(1);
    if (plan === 'loran') {
      idcat = this.props.loggedstate.search.loran;
    }
    if (plan === 'trotamundos') {
      idcat = this.props.loggedstate.search.trotamundos;
    }
    if (categoria === 5 || categoria === 'ebooks') {

      return (
        <Fragment>
          <div className="row">
            <div className="col">
              <div className={"carrusel cl-" + carril.slug + ((categoria === 'ebooks')?(' ebooks'):(''))}>
                {carril.slug === 'docentes' ? (<div className="encabezado d-flex justify-content-between"><div className="globo d-flex flex-column justify-content-center align-items-center "><span className="catego adultos">{carril.titulo}</span></div> <Link className="d-flex align-items-center" onClick={() => this.ira(parseInt(carril.id), carril.titulo)} to={"/" + slugcat + "/" + carril.slug +((categoria === 'ebooks')?('?ebooks=true'):(''))}>VER TODO  <SvgFlecha1 /></Link></div>):(<div className="encabezado d-flex justify-content-between"><div className="globo d-flex flex-column justify-content-center align-items-center "><span className="catego">{carril.titulo}</span><span className="txtanios">años</span></div> <Link className="d-flex align-items-center" onClick={() => this.ira(parseInt(carril.id), carril.titulo)} to={"/" + slugcat + "/" + carril.slug +((categoria === 'ebooks')?('?ebooks=true'):(''))}>VER TODO  <SvgFlecha1 /></Link></div>)}
                
                <div className="pista">
                  <Carousel breakPoints={this.breakPoints} pagination={false}>

                    {carril.libros.map((libro) => (

                      <div key={libro.sap} className="publicacion">
                        <Link to={"/publicacion/" + libro.sap}>
                        <div className="iconos">
                          {((libro.ebook) && (((libro.ebook.length > 1)&&(<div className="ebook"><AodIcon /></div>))))}
                          {((libro.audio) && (((libro.audio.length > 1)&&(<div className="audio"><HeadphonesIcon /></div>))))}
                          {/*((libro.trailer) && (((libro.trailer.length > 1)&&(<div className="trailer"><OndemandVideoIcon /></div>))))*/}
                          {((libro.novedad === 1) && (<div className="novedad" />))}
                          </div>
                          <img className="portada" srcSet={"/uploads/portadas/" + libro.sap + "_th.jpg 1x, /uploads/portadas/" + libro.sap + "_th@2x.jpg 2x"} />
                          <div className="datosThumb">
                            <div className="titulo">{libro.titulo}</div>
                            <div className="autor">{libro.autor}</div>
                          </div>
                        </Link>
                      </div>

                    ))}

                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          {(() => {
            if (carril.novedades) {
              return (
                <div className="row contnovedades">
                  <div className="col">

                    {(carril.novedades.length > 1) ? (
                      <Carousel itemsToShow={1} showArrows={false} >
                        {carril.novedades.map((libro) => (
                          <Novedades key={libro.sap} titulo={libro.titulo} autor={libro.autor} sap={libro.sap} />
                        ))}
                      </Carousel>) : (
                      carril.novedades.map((libro) => (
                        <Novedades key={libro.sap}  titulo={libro.titulo} autor={libro.autor} sap={libro.sap} />
                      )
                      )
                    )
                    }


                  </div>
                </div>
              )
            }
            return null;
          })()}

          {(() => {
            if (carril.podcasts) {
              return (
                <div className="row">
                  <div className="col spotify">

                    {(carril.podcasts.length > 1) ? (
                      <Carousel itemsToShow={1} showArrows={false} >
                        {carril.podcasts.map((podcast) => (
                          <SpotifyFrame key={podcast.sap} iframe={podcast.liga} sap={podcast.sap} />
                        ))}
                      </Carousel>) : (
                      carril.podcasts.map((podcast) => (
                        <SpotifyFrame key={podcast.sap} iframe={podcast.liga} sap={podcast.sap} />
                      )
                      )
                    )
                    }


                  </div>
                </div>
              )
            }
            return null;
          })()}

        </Fragment>
      );

    } else {

      return (
        <Fragment>
          <div className="row">
            <div className="col">
              <div className={"carrusel cl-" + ((categoria === 8) ? (slugcat) : (carril.slug))} >
                <div className="encabezado d-flex justify-content-between"><div className="globotit d-flex flex-column justify-content-center "><span className="catego"> <span dangerouslySetInnerHTML={{ __html: carril.titulo }} /></span></div> <Link className="d-flex align-items-center" onClick={() => this.ira(((categoria === 8)) ? ([idcat, parseInt(carril.id)]) : (parseInt(carril.id)), ((categoria === 8)&&(txtplan+' '))+carril.titulo)} to={((categoria === 8) ? ('/planes') : ('')) + "/" + slugcat + "/" + carril.slug}>VER TODO <SvgFlecha1 /></Link></div>
                <div className="pista">
                  <Carousel breakPoints={this.breakPoints} pagination={false}>

                    {carril.libros.map((libro) => (

                      <div className="publicacion">
                        <Link to={"/publicacion/" + libro.sap}>
                          <div className="iconos">
                          {((libro.ebook) && (((libro.ebook.length > 1)&&(<div className="ebook"><AodIcon /></div>))))}
                          {((libro.audio) && (((libro.audio.length > 1)&&(<div className="audio"><HeadphonesIcon /></div>))))}
                          {/*((libro.trailer) && (((libro.trailer.length > 1)&&(<div className="trailer"><OndemandVideoIcon /></div>))))*/}
                          {((libro.novedad === 1) && (<div className="novedad" />))}
                          </div>
                          <img className="portada" srcSet={"/uploads/portadas/" + libro.sap + "_th.jpg 1x, /uploads/portadas/" + libro.sap + "_th@2x.jpg 2x"} />
                          <div className="datosThumb">
                            {(libro.nivel !== '0' && libro.nivel !== '' && libro.nivel !== null) && (<div className="nivel">Nivel {libro.nivel}</div>)}
                            <div className="titulo">{libro.titulo}</div>
                            <div className="autor">{libro.autor}</div>
                          </div>
                        </Link>
                      </div>

                    ))}

                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          {(() => {
            if (carril.novedades) {
              return (
                <div className="row contnovedades">
                  <div className="col">
                    {(carril.novedades.length > 1) ? (
                      <Carousel itemsToShow={1} showArrows={false} >
                        {carril.novedades.map((libro) => (
                          <Novedades titulo={libro.titulo} autor={libro.autor} sap={libro.sap} />
                        ))}
                      </Carousel>) : (
                      carril.novedades.map((libro) => (
                        <Novedades titulo={libro.titulo} autor={libro.autor} sap={libro.sap} />
                      )
                      )
                    )
                    }
                  </div>
                </div>
              )
            }
            return null;
          })()}

          {(() => {
            if (carril.podcasts) {
              return (
                <div className="row">
                  <div className="col spotify">

                    {(carril.podcasts.length > 1) ? (
                      <Carousel itemsToShow={1} showArrows={false} >
                        {carril.podcasts.map((podcast) => (
                          <SpotifyFrame iframe={podcast.liga} sap={podcast.sap} />
                        ))}
                      </Carousel>) : (
                      carril.podcasts.map((podcast) => (
                        <SpotifyFrame iframe={podcast.liga} sap={podcast.sap} />
                      )
                      )
                    )
                    }


                  </div>
                </div>
              )
            }
            return null;
          })()}
        </Fragment>
      );

    }



  }
}

const mapStateToProps = state => ({
  loggedstate: state
})


const mapDispatchToProps = (dispatch) => {
  return {
    overwriteCats: (cat) => dispatch({ type: 'OVERWRITE_CATS', cat: cat }),
    dispatch,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Carrusel2));