import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import ReCaptchaV2 from 'react-google-recaptcha';
import { FormErrors } from './FormErrors.js';

class FormaEditar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      trabajo: '',
      nombre: '',
      paterno: '',
      materno: '',
      email: '',
      telefono: '',
      cp: '',
      password: '',
      password2: '',
      rol: 'usuario',
      formErrors: { nombre: '', paterno: '', materno: '', email: '', password: '', password2: '', trabajo: '' },
      trabajoValid: false,
      nombreValid: false,
      paternoValid: false,
      maternoValid: false,
      emailValid: false,
      passwordValid: false,
      passwordValid2: false,
      formValid: false
    };
  }

  peticionAxios() {
    let formData = new FormData();
    formData.append('user', this.props.user);
    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'user.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        this.setState({ success: data[0].resultados });

        if (this.state.success === 1) {
          this.setState({
            trabajo: data[0].trabajo,
            nombre: data[0].nombre,
            paterno: data[0].paterno,
            materno: data[0].materno,
            email: data[0].email,
            telefono: data[0].telefono,
            cp: data[0].cp,
            rol: data[0].rol
          }, () => {this.validateFieldsOnMount();});
        }
      })
      .catch(function (response) {
        //handle error

        console.log(response)
      });
  }

  validateFieldsOnMount() {
    const { nombre, paterno, materno, email, password, password2, trabajo } = this.state;
  
    this.validateFieldWithDelay('nombre', nombre, 0.1);
    this.validateFieldWithDelay('paterno', paterno, 0.2);
    this.validateFieldWithDelay('materno', materno, 0.3);
    this.validateFieldWithDelay('email', email, 0.4);
    this.validateFieldWithDelay('password', password, 0.5);
    this.validateFieldWithDelay('password2', password2, 0.6);
    this.validateFieldWithDelay('trabajo', trabajo, 0.7);
  }
  
  validateFieldWithDelay(fieldName, value, delayInSeconds) {
    setTimeout(() => {
      this.validateField(fieldName, value);
    }, delayInSeconds * 500); // Multiply delayInSeconds by 500 to convert to milliseconds
  }

  componentDidMount() {
    this.peticionAxios();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.user !== prevProps.user) {
      this.peticionAxios();
    }
  }

  handleToken(value) {
    this.setState({ token: true });
  }
  handleExpire() {
    this.setState({ token: false });
  }
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      { [name]: value },
      () => { this.validateField(name, value) });
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let nombreValid = this.state.nombreValid;
    let paternoValid = this.state.paternoValid;
    let maternoValid = this.state.maternoValid;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let passwordValid2 = this.state.passwordValid2;
    let trabajoValid = this.state.trabajoValid;

    switch (fieldName) {
      case 'nombre':
        nombreValid = value.length >= 3;
        fieldValidationErrors.nombre = nombreValid ? '' : 'Nombre debe tener más de 2 caracteres';
        break;
      case 'paterno':
        paternoValid = value.length >= 3;
        fieldValidationErrors.paterno = paternoValid ? '' : 'Apellido paterno debe tener más de 2 caracteres';
        break;
      case 'materno':
        maternoValid = value.length >= 3;
        fieldValidationErrors.materno = maternoValid ? '' : 'Apellido materno debe tener más de 2 caracteres';
        break;
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Necesita ingresar un correo electrónico válido';
        break;
      case 'password':
        passwordValid = (value.length >= 8 || value === "");
        fieldValidationErrors.password = passwordValid ? '' : 'La contraseña debe ser mayor a 8 caracteres';
        break;
      case 'password2':
        passwordValid2 = this.state.password === this.state.password2;
        fieldValidationErrors.password2 = passwordValid2 ? '' : 'Las contraseñas no coinciden';
        break;
      case 'trabajo':
          //trabajoValid = this.state.trabajo;
          trabajoValid = value !== "";
          fieldValidationErrors.trabajo = trabajoValid ? '' : 'Elija un área de trabajo';
          break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      nombreValid: nombreValid,
      paternoValid: paternoValid,
      maternoValid: maternoValid,
      emailValid: emailValid,
      passwordValid: passwordValid,
      passwordValid2: passwordValid2,
      trabajoValid: trabajoValid
    }, () => {this.validateForm()});
  }

  validateForm() {
    const {emailValid,passwordValid,passwordValid2,paternoValid,maternoValid,nombreValid} = this.state;
    this.setState({
      formValid:
        this.state.emailValid &&
        this.state.passwordValid &&
        this.state.passwordValid2 &&
        this.state.paternoValid &&
        this.state.maternoValid &&
        this.state.nombreValid
    });
  }
  errorClass(error) {
    return (error.length === 0 ? '' : 'has-error');
  }
  handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    let formData = new FormData();
    formData.append('user', this.props.user)
    formData.append('trabajo', this.state.trabajo)
    formData.append('nombre', this.state.nombre)
    formData.append('paterno', this.state.paterno)
    formData.append('materno', this.state.materno)
    formData.append('email', this.state.email)
    formData.append('telefono', this.state.telefono)
    formData.append('cp', this.state.cp)
    formData.append('password', this.state.password)
    formData.append('password2', this.state.password2)
    formData.append('rol', this.state.rol)
    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'editar_usuario.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        document.getElementById("closeeditarusuario").click();
      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });
  }

  render() {
    return (
      this.state.success === 1 ? (
        <div>
          <form onSubmit={e => this.handleFormSubmit(e)} >
            <h1>Editar perfil</h1>
            <div className="mb-3">
              <input className={`form-control ${this.errorClass(this.state.formErrors.nombre)}`} name="nombre" value={this.state.nombre} onChange={(event) => this.handleUserInput(event)} type="text" placeholder="Nombre(s)" />
              <input className={`form-control ${this.errorClass(this.state.formErrors.paterno)}`} name="paterno" value={this.state.paterno} onChange={(event) => this.handleUserInput(event)} type="text" placeholder="Apellido Paterno" />
              <input className={`form-control ${this.errorClass(this.state.formErrors.materno)}`} name="materno" value={this.state.materno} onChange={(event) => this.handleUserInput(event)} type="text" placeholder="Apellido Materno" />
              <input className={`form-control ${this.errorClass(this.state.formErrors.email)}`} name="email" value={this.state.email} onChange={(event) => this.handleUserInput(event)} type="email" placeholder="Correo electrónico" /> {this.state.success === 0 && (<p className="has-error">Este correo ya está registrado por otro usuario</p>)}
              <input className={`form-control `} name="telefono" value={this.state.telefono} onChange={(event) => this.handleUserInput(event)} type="number" pattern="[0-9]*" placeholder="Teléfono" />
              <input className={`form-control `} name="cp" value={this.state.cp} onChange={(event) => this.handleUserInput(event)} type="number" pattern="[0-9]*" placeholder="Código postal" />
              <span>Deje en blanco los campos de contraseña si no desea cambiarla</span>
              <input className={`form-control ${this.errorClass(this.state.formErrors.password)}`} name="password" type="password" value={this.state.password} onChange={(event) => this.handleUserInput(event)} placeholder="Contraseña" />
              <input className="form-control" name="password2" type="password" onChange={(event) => this.handleUserInput(event)} value={this.state.password2} placeholder="Repetir contraseña" />
              <div className="radiogroup">
              {(this.props.loggedstate.login.rol === 'admin') && (
                <><input type="radio" id="trabajo0" name="trabajo" value="0" onChange={(event) => this.handleUserInput(event)} checked={this.state.trabajo === 0} />
                <label htmlFor="trabajo0">No aplica</label></>
              )}
                <input type="radio" id="trabajo1" name="trabajo" value="1" onChange={(event) => this.handleUserInput(event)} checked={this.state.trabajo === '1'} />
                <label htmlFor="trabajo1">Colegio</label>
                <input type="radio" id="trabajo2" name="trabajo" value="2" onChange={(event) => this.handleUserInput(event)} checked={this.state.trabajo === 2} />
                <label htmlFor="trabajo2">Librería</label>
              </div><br/>
              {(this.props.loggedstate.login.rol === 'admin') ? (
                <div className="radiogroup">
                  <input type="radio" id="rol1" name="rol" value="admin" onChange={(event) => this.handleUserInput(event)} checked={this.state.rol === 'admin'} />
                  <label htmlFor="rol1">Administrador</label>
                  <input type="radio" id="rol2" name="rol" value="usuario" onChange={(event) => this.handleUserInput(event)} checked={this.state.rol === 'usuario'} />
                  <label htmlFor="rol2">Usuario</label>
                </div>
              ) : ('')}

            </div>
            <button className="rojo" type="submit" disabled={!this.state.formValid}>GUARDAR CAMBIOS</button>
            <FormErrors formErrors={this.state.formErrors} />
          </form>
        </div>
      ) : (
        <span>cargando</span>
      )

    )
  }
}

const mapStateToProps = state => ({
  loggedstate: state
})

export default connect(mapStateToProps)(FormaEditar);