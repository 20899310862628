import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import ReCaptchaV2 from 'react-google-recaptcha';
import { FormErrors } from './FormErrors.js';
import { Link } from "react-router-dom";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

class FormaAgregarColeccion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      colecciones: false,
      updatedKey: this.props.updatedKey
    };
    this.Content = this.Content.bind(this);
  }


  peticionAxios = () => {
    let formData = new FormData();
    formData.append('user', this.props.loggedstate.login.user);
    formData.append('sap', this.props.sap);
    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'publicacioncoleccion.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        this.setState({ success: data[0].resultados });

        if (this.state.success === 1) {
          this.setState({
            colecciones: data[0].colecciones
          });
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });
  }
  componentDidMount() {
    this.peticionAxios();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loggedstate.login.logged !== prevProps.loggedstate.login.logged || this.state.updatedKey !== prevState.updatedKey) {
      this.peticionAxios();
    }
  }

  cerrar() {
    document.getElementById("closeeditarusuario").click();
    //document.querySelectorAll(".modal-backdrop").forEach(el => el.classList.remove("modal-backdrop"));
  }

  add(coleccion) {
    let formData = new FormData();
    formData.append('coleccion', coleccion);
    formData.append('sap', this.props.sap);
    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'add.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        this.peticionAxios();
      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });
  }

  delete(coleccion) {
    let formData = new FormData();
    formData.append('coleccion', coleccion);
    formData.append('sap', this.props.sap);
    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'delete.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        this.peticionAxios();
      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });
  }


  Content() {
    let content = [];
    content.push(<button type="button" data-bs-toggle="modal" data-bs-target="#agregarcoleccionModal">Crear Nueva Colección</button>)
  
    if (this.state.colecciones !== false) {
      this.state.colecciones.map((coleccion) => (
        content.push((coleccion.existe == 1) ? (
          <div className="botgroup">
            <Link onClick={() => this.cerrar()} className="existe" to={"/perfil/colecciones/" + coleccion.coleccion}>Mostrar <i>{coleccion.nombre}</i></Link>
            <button className="delete" onClick={() => this.delete(coleccion.coleccion)}><HighlightOffIcon /> quitar</button>
          </div>
        ) : (
          <div className="botgroup">
            <Link onClick={() => this.cerrar()} className="existe" to={"/perfil/colecciones/" + coleccion.coleccion}>Mostrar <i>{coleccion.nombre}</i></Link>
            <button className="add" onClick={() => this.add(coleccion.coleccion)}><AddCircleOutlineIcon /> agregar</button>
          </div>
        ))
      ))
    }
    return content;
  }

  render() {
    var logged = this.props.loggedstate.login.logged;
    var sap = this.props.sap;

    if (logged) {
      return (
        <div className="divadd">
          <h2>Agregar a colección</h2>
          <this.Content />
        </div>
      )
    } else {
      return (
        <div>Debe de estar registrado para poder crear colecciones</div>
      )
    }

  }
}

const mapStateToProps = state => ({
  loggedstate: state
})

export default connect(mapStateToProps)(FormaAgregarColeccion);