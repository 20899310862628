import React, { useState, useEffect } from "react";
import ReactQuill, {Quill} from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);


const PostForm = ({ onSave, articleid = false }) => {
  const [autor, setAutor] = useState("");
  const [titulo, setTitulo] = useState("");
  const [contenidos, setContenidos] = useState("");
  const [image, setImage] = useState(null);
  const [prioridad, setPrioridad] = useState(false);
  const [publicar, setPublicar] = useState(false);
  const [existingArticle, setExistingArticle] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (articleid) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_URL}obtenerarticulo.php?id=${articleid}`
          );
          const { data } = response;
          setAutor(data.autor);
          setTitulo(data.titulo);
          setContenidos(data.contenidos);
          setPrioridad(data.prioridad);
          setPublicar(data.publicar);
          setExistingArticle(true);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [articleid]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!autor || !titulo || !contenidos) {
      alert("Por favor ingresa toda la información.");
      return;
    }

    const formData = new FormData();
    formData.append("autor", autor);
    formData.append("titulo", titulo);
    formData.append("contenidos", contenidos);
    formData.append("prioridad", prioridad);
    formData.append("publicar", publicar);
    if (image) {
      formData.append("image", image);
    }

    try {
      let response;
      if (existingArticle) {
        formData.append("articleid", articleid);
        response = await axios.post(
          `${process.env.REACT_APP_URL}guardararticulo.php`,
          formData
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_URL}guardararticulo.php`,
          formData
        );
      }

      onSave(response.data.id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form className="formablog" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="autorInput">Nombre del Autor:</label>
        <input
          type="text"
          id="autorInput"
          value={autor}
          onChange={(event) => setAutor(event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="tituloInput">Título del Artículo:</label>
        <input
          type="text"
          id="tituloInput"
          value={titulo}
          onChange={(event) => setTitulo(event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="contenidosInput">Contenido:</label>
        <ReactQuill
          id="contenidosInput"
          className="cont_articulo"
          value={contenidos}
          onChange={setContenidos}
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }],
              [{ size: [] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              [{ align: [] }], // Added align options
              [{ color: [] }, { background: [] }],
              ["link", "image", "video"],
              ["clean"],
            ],
            clipboard: {
              matchVisual: false,
            },
            imageResize: {
              parchment: Quill.import('parchment'),
              modules: ['Resize', 'DisplaySize']
            },
            
          }}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "align", // Added align format
            "link",
            "image",
            "video",
            "color",
            "background"
          ]}
          placeholder="Escribe algo..."
        />

      </div>
      <div>
        <label htmlFor="imageInput">Imagen principal:</label>
        <input
          type="file"
          id="imageInput"
          accept="image/png, image/jpeg, image/jpg"
          onChange={(event) => setImage(event.target.files[0])}
        />
        <p><strong>La imagen debe ser en formato jpg con un tamaño de 1600 pixeles de ancho x 540 pixeles de alto y sin texto.</strong></p>
      </div>
      <div>
        <label>
          <input
            className="form-check-input"
            type="checkbox"
            checked={prioridad}
            onChange={(event) => setPrioridad(event.target.checked)}
          />{" "}
          Poner en carrusel
        </label>
      </div>
      <div>
        <label>
          <input
            className="form-check-input"
            type="checkbox"
            checked={publicar}
            onChange={(event) => setPublicar(event.target.checked)}
          />{" "}
          Publicar (si no se selecciona se mantiene como borrador)
        </label>
      </div>
      <button type="submit" className="vermas">
        Salvar
      </button>
    </form>
  );
};

export default PostForm;
