import React, { Component } from "react";
import axios from "axios";
import ReCaptchaV2 from 'react-google-recaptcha';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { FormErrors } from './FormErrors.js';

class FormaRecuperar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      email: '',
      formErrors: { email: '' },
      emailValid: false,
      formValid: false
    };
  }
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      { [name]: value },
      () => { this.validateField(name, value) });
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Necesita ingresar un correo electrónico válido';
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid
    }, this.validateForm);
  }
  validateForm() {
    this.setState({ formValid: this.state.emailValid });
  }
  errorClass(error) {
    return (error.length === 0 ? '' : 'has-error');
  }
  handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    let formData = new FormData();
    formData.append('email', this.state.email)

    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'recuperar.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        this.setState({ success: data[0].resultados });
      })
      .catch(function (response) {
        //handle error

        console.log(response)
      });
  }
  render() {
    return (
      <div>
        <form onSubmit={e => this.handleFormSubmit(e)}>
          <h1><button><KeyboardArrowLeftIcon /></button> Recuperar contraseña</h1>
          <p>Ingresa el nombre usuario o correo electrónico asociados a tu cuenta y te enviaremos un enlace para reestablecer tu contraseña.</p>
          <div className="mb-3">
            <input className={`form-control ${this.errorClass(this.state.formErrors.email)}`} name="email" value={this.state.email} onChange={(event) => this.handleUserInput(event)} type="email" placeholder="Correo electrónico" /> {(this.state.success === 0) && (<p className="has-error">Este correo no está registrado</p>)} {(this.state.success === 1) && (<p className="has-error">Error al enviar el correo, contacte a un administrador.</p>)} {(this.state.success === 2) && (<p>Correo enviado exitosamente, en caso de no encontrarlo revise su carpeta de correo no deseado.</p>)}
          </div>
          <button className="rojo" type="submit" disabled={!this.state.formValid}>ENVIAR</button>
          <FormErrors formErrors={this.state.formErrors} />
        </form>
      </div>
    )
  }
}

export default FormaRecuperar;