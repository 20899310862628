export const AGREGAR_PRODUCTO = "AGREGAR_PRODUCTO";
export const AGREGAR_PRODUCTO_EXITO = "AGREGAR_PRODUCTO_EXITO";
export const AGREGAR_PRODUCTO_ERROR = "AGREGAR_PRODUCTO_ERROR";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const ADD_CAT = "ADD_CAT";
export const REMOVE_CAT = "REMOVE_CAT";
export const UPDATE_TOTALES = "UPDATE_TOTALES";
export const SEND_PARAMETERS = "SEND_PARAMETERS";
export const OVERWRITE_CATS = "OVERWRITE_CATS";
export const CATCH_VARS = "CATCH_VARS";
export const MENU_INICIO = "MENU_INICIO";
export const SET_EBOOKS = "SET_EBOOKS";
export const SET_AUDIOLIBROS = "SET_AUDIOLIBROS";
export const RESET_SEARCH = "RESET_SEARCH";
export const SET_IDS_PLANES = "SET_IDS_PLANES";