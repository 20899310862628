
import {
    ADD_CAT,
    REMOVE_CAT,
    UPDATE_TOTALES,
    SEND_PARAMETERS,
    OVERWRITE_CATS,
    CATCH_VARS,
    MENU_INICIO,
    SET_EBOOKS,
    SET_AUDIOLIBROS,
    RESET_SEARCH,
    SET_IDS_PLANES

} from "../types";

const initialState = {
    totales: 0,
    conteo: [],
    palabras: [],
    mensaje: '',
    current_cat: 5,
    child_cat: 'loran',
    catIds: [],
    parametros: false,
    ebooks: false,
    audiolibros: false,
    loran: false,
    trotamundos: false
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case RESET_SEARCH: {
            return {
                ...state,
                catIds: [],
                parametros: false,
                ebooks: false,
                audiolibros: false,
            }
        }
        case ADD_CAT:
            let idAlreadyExists = state.catIds.indexOf(action.chosenId) > -1;
            // make a copy of the existing array
            let catIds = state.catIds.slice();

            if (idAlreadyExists) {
                catIds = catIds.filter(id => id !== action.chosenId);
            }
            else {
                // modify the COPY, not the original
                catIds.push(action.chosenId);
            }

            return {
                // "spread" the original state object
                ...state,
                // but replace the "catIds" field
                catIds
            };

        case REMOVE_CAT:

            let catIds2 = state.catIds.slice();
            //let numbers = [1,2,3,4];
            var i = catIds2.indexOf(parseInt(action.chosenId));
            if (i >= 0) {
                catIds2.splice(i, 1);
            }
            return {
                // "spread" the original state object
                ...state,
                // but replace the "catIds" field
                catIds: catIds2
            };
        case UPDATE_TOTALES:
            return {
                ...state,
                totales: parseInt(action.totales),
                conteo: action.conteo,
                palabras: action.palabras,
                mensaje: action.mensaje
            }

        case SEND_PARAMETERS:
            return {
                ...state,
                parametros: action.parametros,
            }

        case OVERWRITE_CATS:
            console.log('Cat ',action.cat);
            var newcat = [];
            if (action.cat === 0 || action.cat === null  || action.cat === 'null' || (Array.isArray(action.cat) && action.cat.length === 1 && action.cat[0] === null)) {
                newcat = [];
            } else {
                newcat = [].concat(action.cat);
            }
            return {
                ...state,
                catIds: newcat,
                parametros: false,
            }

        case CATCH_VARS:
            var par_temp = false;
            if (action.parametros.length > 0) {
                par_temp = action.parametros;
            } else {
                par_temp = false;
            }
            //newcat = [];
            if (action.cat === 0) {
                newcat = [];
            } else {
                newcat = [].concat(action.cat);
            }
            return {
                ...state,
                parametros: par_temp,
                catIds: newcat
            }
        case MENU_INICIO: {
            return {
                ...state,
                current_cat: action.current_cat,
                child_cat: action.child_cat
            }
        }
        case SET_EBOOKS: {
            return {
                ...state,
                ebooks: action.ebooks,
            }
        }
        case SET_AUDIOLIBROS: {
            return {
                ...state,
                audiolibros: action.audiolibros,
            }
        }
        case SET_IDS_PLANES: {
            return {
                ...state,
                loran: parseInt(action.loran),
                trotamundos: parseInt(action.trotamundos),
            }
        }
        default:
            return state;
    }
}