import React, { Component } from "react";
import Filtros from "../components/Filtros";
import AdminReticula from "../components/AdminReticula";

class AdminPublicaciones extends Component {
    render() {
        return (
            <div className="container-xxl perfil">
                <div className="row fondo_gris">
                    <div className="col px-2">
                    <h1>Administración de Publicaciones</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col px-2">
                        <Filtros />
                        <AdminReticula />
                    </div>
                </div>


            </div>
        );
    }
}

export default AdminPublicaciones;