import React, { useEffect, useState } from "react";

const Cookies = () => {
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);

  useEffect(() => {
    fetch('https://admindpo.grupo-sm.com/documento?web=cookiefirst-general&type=cookies-policy&language=es')
      .then(response => response.json())
      .then(data => {
        setData(data[0].body); // Extract the HTML content from the JSON
      })
      .catch(error => {
        console.error('Error fetching document:', error);
      });
  }, []);

  useEffect(() => {
    fetch('https://admindpo.grupo-sm.com/documento?web=webapp-de-mexico&type=politica-de-privacidad&language=es')
      .then(response => response.json())
      .then(data => {
        setData2(data[0].body); // Extract the HTML content from the JSON
      })
      .catch(error => {
        console.error('Error fetching document:', error);
      });
  }, []);

  return (
    <div>
      {data && data2 ? (
        <div className="container-xxl politicas">
          <div className="row">
            <div className="col">
              <h2>POLITICA DE COOKIES.</h2>
              {/* Render data as HTML without sanitization (potentially dangerous) */}
              <div dangerouslySetInnerHTML={{ __html: data }}></div>
              <div dangerouslySetInnerHTML={{ __html: data2 }}></div>
            </div>
          </div>
        </div>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default Cookies;