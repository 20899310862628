import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { ReactComponent as SvgFiltros } from '../img/filtros.svg';
import BarraFiltros from "../components/BarraFiltros";

class Filtros extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props)
    this.state = {
      folder: ""
    };
  }

  cambiarURL() {
    const { location } = this.props;
    var loc = location.pathname.split('/');
    var folder = loc[1].toString();
    this.setState({ folder: folder });
  }

  componentDidMount() {
    this.cambiarURL();
  }

  render() {
    const {folder} = this.state;
    return (
      <div id="cont_filtros">
        <div id="filtros" className={(folder !== "") ? ('movil') : ('home')}>
          <button data-bs-toggle="collapse" data-bs-target="#colapseFiltros" aria-expanded="false" aria-controls="colapseFiltros"><SvgFiltros /></button>
        </div>
        <div className={"collapse" + ((folder !== "") ? (' movil') : (' home'))} id="colapseFiltros"><BarraFiltros /></div>
      </div>
    )
  }
}

export default withRouter(Filtros);