import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import ReCaptchaV2 from 'react-google-recaptcha';
import { FormErrors } from './FormErrors.js';
import { Link } from "react-router-dom";

class FormaAgregarColeccion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombrecoleccion: false
        };
    }

    cerrar() {
        document.getElementById("closeaddColeccion").click();
        //document.querySelectorAll(".modal-backdrop").forEach(el => el.classList.remove("modal-backdrop"));
    }

   add() {
        let formData = new FormData();
        formData.append('nombrecoleccion', this.state.nombrecoleccion);
        formData.append('sap', this.props.sap);
        formData.append('usuario', this.props.loggedstate.login.user);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'addcoleccion.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                document.getElementById("closeaddColeccion").click();
            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }


    render() {
        var logged = this.props.loggedstate.login.logged;

        if (logged) {
            return (
                <div className="divadd">
                <h2>Crear Colección</h2>
                    <p>Agregar a la colección:</p>
                    <input type="text" placeholder="Nombre de la Colección" onChange={(e) => this.setState({nombrecoleccion: e.target.value})} />
                    <button disabled={(this.state.nombrecoleccion)?false:true} onClick={(e) => this.add()}>Agregar</button>
                </div>
            )
        } else {
            return (
                <div>Debe de estar registrado para poder crear colecciones</div>
            )
        }

    }
}

const mapStateToProps = state => ({
    loggedstate: state
})

export default connect(mapStateToProps)(FormaAgregarColeccion);