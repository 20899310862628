import React, { useRef, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import PDFViewer from '../components/PDFViewer';

const ViewerPDF = (props) => {
    const { match } = props;
    const documentId = match.params.document;
    const pdfUrl = process.env.REACT_APP_URL + 'getpdf.php?file=' + documentId;

    const containerRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            // Adjust containerRef height
            if (containerRef.current) {
                const conNavElement = document.querySelector('.conNav');
                const conNavHeight = conNavElement ? conNavElement.clientHeight : 0;
    
                containerRef.current.style.height = `${window.innerHeight - conNavHeight - 150}px`;
                containerRef.current.style.marginBottom = '80px';
            }
        };
    
        // Use window.onload to make sure all elements are loaded
        window.onload = () => {
            handleResize(); // Initial setup after all elements are loaded
            window.addEventListener('resize', handleResize);
        };
    
        return () => {
            // Cleanup event listener on component unmount
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    

    return (
        <div className="container-xxl">
            <div className="row podcasts articulos g-2" ref={containerRef}>
                <h1>Visor PDF: {documentId}.pdf</h1>
                <div
                    className='visor'
                    style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <PDFViewer pdfUrl={pdfUrl} />
                </div>
            </div>
        </div>
    );
};

export default withRouter(ViewerPDF);
