import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SendExcel = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [processProgress, setProcessProgress] = useState(0);
  const [finalProcess, setFinalProcess] = useState(0);

  useEffect(() => {
    let intervalId;

    const checkProgress = () => {
      axios
        .get(`${process.env.REACT_APP_URL}admin/excelProgress.php`)
        .then((response) => {
          setProcessProgress(response.data.progress);

          if (response.data.progress === 100) {
            clearInterval(intervalId);
            setFinalProcess(100);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (uploadProgress === 100 && finalProcess !== 100) {
      intervalId = setInterval(checkProgress, 3000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [uploadProgress, finalProcess]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
  };

  const handleFileUpload = () => {
    if (!selectedFile) {
      alert('Por favor, seleccione un archivo Excel.');
      return;
    }

    if (
      window.confirm(
        'Esta operación destruirá la información del catálogo actual y la reemplazará con la proveniente del documento.\nEsto incluye Taxonomías, Colecciones, Planes y el listado de libros.\n¿Está seguro de querer continuar?'
      )
    ) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      setFinalProcess(0);

      axios
        .post(`${process.env.REACT_APP_URL}admin/excelAxios.php`, formData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        })
        .then((response) => {
          console.log(response.data);
          setFinalProcess(100);
          setUploadProgress(0);
          setProcessProgress(0);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div id='sendexcel'>
      {uploadProgress === 0 ? (
        <>
          <input
            type="file"
            name="file"
            id="file"
            accept=".xls,.xlsx"
            onChange={handleFileChange}
          />
          <button className='vermas' onClick={handleFileUpload}>Procesar</button>
          {finalProcess === 100 && <p>Finalizado: {finalProcess}%</p>}
        </>
      ) : (
        <>
          {uploadProgress > 0 && processProgress === 0 && (
            <p>Subiendo archivo: {uploadProgress}%</p>
          )}
          {processProgress > 0 && finalProcess < 100 && (
            <p>Integrando a la base de datos: {processProgress}%</p>
          )}
          {finalProcess === 100 && <p className='final'>Finalizado: {finalProcess}%</p>}
          <div>
            <p className="has-error">IMPORTANTE: NO SALGA DE ESTA PÁGINA HASTA CONCLUIR EL PROCESO. Hacer caso omiso a este mensaje puede resultar en daños a la base de datos.</p>
            <p>Al utilizar esta opción las imágenes deben ser subidas a las carpetas <i>uploads/portadas</i> y <i>uploads/portadas</i> usando un cliente FTP</p>
          </div>
        </>
      )}
    </div>
  );
};

export default SendExcel;
