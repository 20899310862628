import React, { Component, Fragment } from "react";
import Carousel from 'react-elastic-carousel';
import { Link } from "react-router-dom";
import axios from 'axios';

class CarruselRelacionados extends Component {

    constructor(props){
        super(props)

        this.breakPoints = [
          { width: 1, itemsToShow: 2.5, showArrows:false },
          { width: 550, itemsToShow: 2.5, showArrows:false },
          { width: 992, itemsToShow: 4, showArrows:false },
          { width: 1150, itemsToShow: 4 },
          { width: 1450, itemsToShow: 5 },
          { width: 1750, itemsToShow: 5 },
        ]
          
        this.state = {
          libros: []
        }
          
      }

      peticionAxios(){

        const url = process.env.REACT_APP_URL + 'relacionados.php';
    
        axios.get(url+'?sap='+this.props.sap
        ).then(response => response.data)
        .then((data) => {
          this.setState({ libros: data })
          
         })
      
      }

      componentDidMount() {
        this.peticionAxios();
      }

  render() {

      return (
        <Fragment>

        <div className="row librosrelacionados">
          <div className="col">
              <h2>Libros relacionados</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="carrusel">
              <div className="pista">
                <Carousel breakPoints={this.breakPoints} pagination={false}>

                  {this.state.libros.map((pubs) => (
                    pubs.libros.map((libro) => (

                    <div className="publicacion">
                      <Link to={"/publicacion/" + libro.sap}>
                      {((libro.novedad === 1) && (<div className="novedad" />))}
                        <img className="portada" srcSet={"/uploads/portadas/" + libro.sap + "_th.jpg 1x, /uploads/portadas/" + libro.sap + "_th@2x.jpg 2x"} />
                        <div className="datosThumb">
                        <div className="titulo">{libro.titulo}</div>
                        <div className="autor">{libro.autores}</div>
                        </div>
                      </Link>
                    </div>

                    ))
                  ))}

                </Carousel>
              </div>
            </div>
          </div>
        </div>

        </Fragment>
      );

 



  }
}

export default CarruselRelacionados;