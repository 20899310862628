import React, { Component } from "react";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ReactComponent as InstagramIcon } from '../img/instagram.svg';
import { ReactComponent as Logo } from '../img/logo.svg';
import empresa_resp from '../img/empresa_resp.jpg';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

class Footer extends Component {
  render() {
    return(
        <div className="row footer">
          <hr/>
          <div className="logos d-flex justify-content-end align-items-center"><span className="flex-fill"><Logo className="logo" /></span>
          <a target="_blank" href="https://www.linkedin.com/company/smmexicooficial/"><LinkedInIcon className="linked" /></a>
          <a target="_blank" href="https://www.facebook.com/literaturaSMmexico"><FacebookIcon className="fb"/></a>
          <a target="_blank" href="https://www.instagram.com/smmexicooficial/"><InstagramIcon className="ins"/></a>
          <a target="_blank" href="https://twitter.com/SMMexicoOficial"><TwitterIcon className="tw"/></a>
          <span className="divisor"></span>
          <img className="empresa_resp" srcSet={empresa_resp + " 1x, " + empresa_resp + " 2x"}/></div>
          <hr/>
          <div className="legales"><Link to="/politicas-de-privacidad">POLÍTICA DE PRIVACIDAD</Link>   |   <Link to="/condiciones-de-uso">CONDICIONES DE USO</Link>   |   <Link to="/politicas-de-cookies">POLÍTICA DE COOKIES</Link></div>
        </div>
        );
  }
}

export default Footer;