/*import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PDFViewer = ({ pdfUrl }) => {
  return (
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
      <Viewer fileUrl={pdfUrl} />
    </Worker>
  );
};

export default PDFViewer;*/

import React from 'react';
import { Worker, Viewer, SpecialZoomLevel, ProgressBar, ScrollMode } from '@react-pdf-viewer/core';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewer = ({ pdfUrl }) => {
    //const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const defaultLayoutPluginInstance = defaultLayoutPlugin(

    );

    return (
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <Viewer
                fileUrl={pdfUrl}
                plugins={[
                    defaultLayoutPluginInstance,
                ]}
                defaultScale={SpecialZoomLevel.PageFit}
                renderLoader={(percentages: number) => (
                    <div style={{ width: '240px' }}>
                        <ProgressBar progress={Math.round(percentages)} />
                    </div>
                )}
                scrollMode={ScrollMode.Page}

            />
        </Worker>
    );
};

export default PDFViewer;
