import React, { Component } from "react";

class Error extends Component {
  render() {
    return (
      <div>
        <h1>Ups! Parece que no tienes permisos para estar aquí.</h1>
      </div>
    );
  }
}

export default Error;