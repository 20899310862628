import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import CreateIcon from '@mui/icons-material/Create';
import FormaEditar from "../components/formaEditar";
class Perfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            data: false
        };
      }
    peticionAxios(){
        let formData = new FormData();
        formData.append('user', this.props.loggedstate.login.user);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'user.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ success: data[0].resultados });
                
                if (this.state.success === 1) {
                    this.setState({
                        data: data[0]
                    });
                }
            })
            .catch(function (response) {
                //handle error

                console.log(response)
            });
    }
    componentDidMount(){
        this.peticionAxios();
    }
    componentDidUpdate(prevState){
        if(prevState.data !== this.state.data){
            //this.peticionAxios();
        }
    }
    render() {
        var logged = this.props.loggedstate.login.logged;
        return (
            this.state.data ? (
             <Fragment>
            <div className="container-xxl perfil">
                <div className="row fondo_gris">
                    <div className="col px-2">
                        <h1>Perfil</h1>
                        <h1>{this.state.data.nombre +' '+this.state.data.paterno+' '+this.state.data.materno}</h1>
                        <div className="textomail">{this.state.data.email}</div>
                    </div>
                </div>
                <div id="edicion">
                    <button type="button" data-bs-toggle="modal" data-bs-target="#editarModal"><CreateIcon /></button>
                </div>
                <div className="row">
                    <div className="col px-2">
                        <table className="table">
                            <thead><tr><th scope="col">Teléfono</th><th scope="col">Código Postal</th><th scope="col">Centro de trabajo</th></tr></thead>
                            <tbody className="textodata"><tr><td>{this.state.data.telefono}</td><td>{this.state.data.cp}</td><td>{this.state.data.trabajo === 1 ? ('Colegio'):('')}{this.state.data.trabajo === 2 ? ('Librería'):('')}</td></tr></tbody>
                        </table>
                    </div>
                </div>

                <div className="modal fade" id="editarModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button id="closeeditarusuario" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.peticionAxios()}></button>
                            </div>
                            <div className="modal-body">
                                <FormaEditar user = {this.props.loggedstate.login.user} />
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </Fragment>
            ):(
                <div>Nada que ver</div>
            )
        );
    }
}
const mapStateToProps = state => ({
    loggedstate: state
  })
export default connect(mapStateToProps)(Perfil);