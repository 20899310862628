import React, { Fragment } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { Provider, useSelector } from 'react-redux';
import store from "./store";

import Nav from "./components/Nav";
import Footer from "./components/Footer";

//rutas
import Home from "./pages/Home";
import Token from "./pages/Token";
import Politicas from "./pages/Politicas";
import Cookies from "./pages/Cookies";
import Relacionados from "./pages/Relacionados";
import Catalogos from "./pages/Catalogos";
import Publicacion from "./pages/Publicacion";
import Podcast from "./pages/Podcast";
import Blog from "./pages/Blog";
import Busqueda from './pages/Busqueda';
import Perfil from './pages/Perfil';
import Colecciones from './pages/Colecciones';
import ReticulaColeccion from './components/ReticulaColeccion';
import AdminUsuarios from "./pages/AdminUsuarios";
import AdminPublicaciones from './pages/AdminPublicaciones';
import AdminPodcasts from './pages/AdminPodcasts';
import AdminColecciones from './pages/AdminColecciones';
import AdminBlog from './pages/AdminBlog';
import Error from './pages/Error';
import NotFound from './pages/NotFound';
import Articulo from './pages/Articulo';
import AdminConfig from './pages/AdminConfig';
import Condiciones from './pages/Condiciones';
import ViewerPDF from './pages/ViewerPDF';

function Content() {
  const login = useSelector(state => state.login.logged);
  const rol = useSelector(state => state.login.rol);
  return (
    <Fragment>
      <Route path="/">
        <Nav />
      </Route>
      <div className='fill content'>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/politicas-de-privacidad">
                  <Politicas />
                </Route>
                <Route path="/condiciones-de-uso">
                  <Condiciones />
                </Route>
                <Route path="/politicas-de-cookies">
                  <Cookies />
                </Route>
                <Route path={["/edades/:id", "/colecciones/:id", "/planes/:id"]}>
                  <Catalogos />
                </Route>
                <Route path="/publicacion/:sap">
                  <Publicacion />
                </Route>
                <Route exact path="/podcast">
                  <Podcast />
                </Route>
                <Route path="/podcast/:sap">
                  <Relacionados />
                </Route>
                <Route exact path="/blog">
                  <Blog />
                </Route>
                <Route path="/blog/:articulo">
                  <Articulo />
                </Route>
                <Route path={["/busqueda"]}>
                  <Busqueda />
                </Route>
                <Route path="/visor/:document">
                  <ViewerPDF />
                </Route>
                {(login) ? (
                  <Fragment>
                    <Route exact path="/perfil">
                      <Perfil />
                    </Route>
                    <Route exact path="/perfil/colecciones">
                      <Colecciones />
                    </Route>
                    <Route exact path="/perfil/colecciones/:id">
                      <ReticulaColeccion />
                    </Route>
                    {(rol === "admin") ? (
                      <Fragment>
                        <Route exact path="/administrador">
                          <AdminColecciones />
                        </Route>
                        <Route exact path="/administrador/colecciones">
                          <AdminColecciones />
                        </Route>
                        <Route exact path="/administrador/blog">
                          <AdminBlog />
                        </Route>
                        <Route exact path="/administrador/colecciones/:id">
                          <ReticulaColeccion />
                        </Route>
                        <Route exact path="/administrador/publicaciones">
                          <AdminPublicaciones />
                        </Route>
                        <Route exact path="/administrador/usuarios">
                          <AdminUsuarios />
                        </Route>
                        <Route exact path="/administrador/podcasts">
                          <AdminPodcasts />
                        </Route>
                        <Route exact path="/administrador/config">
                          <AdminConfig />
                        </Route>
                      </Fragment>
                    ) : (null)}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Route path="/perfil">
                      <Error />
                    </Route>
                  </Fragment>
                )}
                <Route path='*'>
                  <NotFound />
                </Route>
              </Switch>
      </div>
    </Fragment>
  )
}


export default function App() {
  return (
    <div className="container-xxl">
      <Router>
        <Helmet>
          <title>SM Literatura</title>
          <meta property="og:title" content="SM Literatura" />
          <meta property="og:url" content="/" />
          <meta property="og:type" content="article" />
          <meta property="og:description" content="Catálogo de publicaciones de SM Literatura" />
          <meta property="og:image" content="https://webapp.grupo-sm.com.mx/img/social.png" />
          <meta property="twitter:title" content="SM Literatura" />
          <meta property="twitter:description" content="Catálogo de publicaciones de SM Literatura" />
          <meta property="twitter:image" content="https://webapp.grupo-sm.com.mx/img/social.png" />
        </Helmet>

        <Provider store={store}>

          <Switch>
            <Route exact path="/token/:token">
              <Token />
            </Route>
            <Route path="/">
              <Content />
            </Route>

          </Switch>
          <Footer />

        </Provider>

      </Router>

    </div>
  );
}