import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import LoopIcon from '@mui/icons-material/Loop';
import axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SendIcon from '@mui/icons-material/Send';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningIcon from '@mui/icons-material/Warning';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Reticula extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props)

        this.state = {
            libros: [],
            nombre: '',
            enviado: null,
            cargado: false,
            data: false,
            adminArea: this.props.loggedstate.login.rol === 'admin'
        }
    }

    datosUsuario() {
        let formData = new FormData();
        formData.append('user', this.props.loggedstate.login.user);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'user.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ success: data[0].resultados });

                if (this.state.success === 1) {
                    this.setState({
                        data: data[0]
                    });
                }
            })
            .catch(function (response) {
                //handle error

                console.log(response)
            });
    }

    peticionAxios() {
        this.setState({ cargado: false });
        let formData = new FormData();
        formData.append('coleccion', this.props.match.params.id);
        formData.append('usuario', this.props.loggedstate.login.user);
        formData.append('isadmin', this.state.adminArea);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'reticula_coleccion.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ libros: data, cargado: true, nombre: data[0].nombre, enviado: data[0].enviado })
            })
    }

    componentDidMount() {
        this.peticionAxios();
        this.datosUsuario();
    }

    delete(sap) {
        let formData = new FormData();
        formData.append('coleccion', this.props.match.params.id);
        formData.append('sap', sap);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'delete.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.peticionAxios();
            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }

    deleteColeccion() {
        let formData = new FormData();
        formData.append('coleccion', this.props.match.params.id);
        formData.append('usuario', this.props.loggedstate.login.user);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'deleteColeccion.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                document.getElementById("closeremoveColeccion").click();
                this.props.history.push('/perfil/colecciones');
            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }

    notify() {
        toast.success("Tu colección ha sido enviada, en breve un asesor de SM se pondrá en contacto contigo", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            hideProgressBar: true
        });
    }

    enviarColeccion() {
        let $saps = [];

        this.state.libros[0].libros.map((libro) => {
            $saps.push(libro.sap);
        })

        let formData = new FormData();
        //Datos
        formData.append('oid', "00D37000000IdId");
        formData.append('lead_source', "WEB APP - LIJ");
        formData.append('country', "Mexico");
        formData.append('CTA__c', "Contacto Directo");
        formData.append('industry', "Privado");
        formData.append('first_name', this.state.data.nombre);
        formData.append('last_name', this.state.data.paterno);
        formData.append('email', this.state.data.email);
        formData.append('zip', this.state.data.cp);
        //SAPS
        formData.append('coleccionid', this.props.match.params.id);
        formData.append('coleccion', this.state.nombre);
        formData.append('usuarioid', this.props.loggedstate.login.user);
        formData.append('saps', $saps);

        for (const pair of formData.entries()) {
            //console.log(`${pair[0]}, ${pair[1]}`);
        }

        axios({
            method: 'post',
            url: 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {

            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });

        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'enviada.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.notify();
                this.peticionAxios();
            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }

    render() {
        //const { items } = this.props;
        //let delay_index = -1;
        const { adminArea } = this.state;
        return (
            <div className="reticula">
                <ToastContainer />
                {(adminArea) ? (
                    <div className="btn-group" role="group" >
                        <h3 className="catego">{this.state.nombre}</h3>
                    </div>
                ) : (
                    <div className="btn-group" role="group" >
                        <h3 className="catego">{this.state.nombre}</h3>
                        <button className="btn-danger" type="button" data-bs-toggle="modal" data-bs-target="#removecoleccionModal"><DeleteForeverIcon /> Eliminar colección</button>
                        <button className={(this.state.enviado) ? ('btn-success') : ('btn-warning')} type="button" onClick={() => this.enviarColeccion()}><SendIcon /> {(this.state.enviado) ? ('Reenviar colección') : ('Enviar colección')}</button>
                    </div>
                )}
                <div className="row" >
                    {
                        (this.state.cargado) ? (
                            (this.state.libros[0].libros.length > 0) ? (
                                this.state.libros.map((pubs) => (
                                    pubs.libros.map((libro, index) => {
                                        return (

                                            <div key={libro.sap} className="col col-6 col-sm-equal col-md-equal col-lg-equal d-flex flex-column justify-content-center align-items-center" >
                                                <div className="publicacion">
                                                    {(adminArea)?(null):(
                                                        <button className="delete" onClick={() => this.delete(libro.sap)}><HighlightOffIcon /> quitar</button>
                                                    )}
                                                    <Link to={"/publicacion/" + libro.sap}>
                                                        <img className="portada" srcSet={"/uploads/portadas/" + libro.sap + "_th.jpg 1x, /uploads/portadas/" + libro.sap + "_th@2x.jpg 2x"} />
                                                        <div className="datosThumb">
                                                            <div className="titulo">{libro.titulo}</div>
                                                            <div className="autor">{libro.autores}</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                        )
                                    })
                                ))
                            ) : (
                                <div className="col col-lg-equal d-flex flex-column justify-content-center align-items-center">
                                    <h1>Colección vacía.</h1><p>Por favor añada publicaciones</p>
                                </div>
                            )
                        ) : (

                            <div className="col col-lg-equal d-flex flex-column justify-content-center align-items-center">
                                <LoopIcon className="spinner" />
                                <p>Cargando</p>
                            </div>
                        )

                    }

                </div>

                <div className="modal fade" id="removecoleccionModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button id="closeremoveColeccion" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="divadd">
                                    <h2 className="alerta"><WarningIcon /> Eliminar Colección</h2>
                                    <p>Eliminar permanentemente <i>{this.state.nombre}</i></p>
                                    <button onClick={(e) => this.deleteColeccion()}>Eliminar</button>
                                </div>
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedstate: state
})

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reticula));