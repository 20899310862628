import React, { Component, Fragment } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as Logo } from '../img/logo.svg';
import { ReactComponent as SvgUser } from '../img/user.svg';
import SvgClose from '@mui/icons-material/Close';
import { ReactComponent as SvgColecciones } from "../img/colecciones.svg"
import FlechaAbajo from '@mui/icons-material/KeyboardArrowDownRounded';
import FormaRegistro from "../components/formaRegistro";
import FormaInicio from "../components/formaInicio";
import FormaRecuperar from "../components/formaRecuperar";
import Autocomplete from "../components/Autocomplete";
import PropTypes from "prop-types";
import axios from 'axios';
import NavAdmin from "./NavAdmin";


class Nav extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      activo: ' ',
      categorias: null,
      current: 'home',
      localslug: null,
      subcat: null,
      submitsearch: false
    };
    this.abrir = this.abrir.bind(this);
    this.cerrar = this.cerrar.bind(this);
    this.ira = this.ira.bind(this);

  }

  abrir(e) {
    this.setState({
      activo: 'activo'
    });
    document.getElementById("CampoBusqueda").focus();
    const menuToggle = document.querySelector('#hamburguesita[aria-expanded="true"]');
    if (menuToggle) {
      menuToggle.click();
    }
  }

  cerrar(e) {
    this.setState({
      activo: ' '
    });
    document.getElementById('CampoBusqueda').value = " ";
  }

  cambiarURL() {
    var loc = this.props.location.pathname.split('/');
    var folder = loc[1].toString();
    var localslug = "";
    var subcat = "";
    if (loc.length > 2) {
      localslug = loc[2].toString();
    }
    if (loc.length > 3) {
      subcat = loc[3].toString();
    }
    this.setState({ current: folder, localslug: localslug, subcat: subcat });

    if (folder !== 'busqueda' && folder !== 'administrador' && folder !== 'edades' && folder !== 'colecciones' && folder !== 'planes') {
      this.props.resetSearch();
    }
  }

  peticionAxios() {
    const url = process.env.REACT_APP_URL + 'categorias.php';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        this.setState({ categorias: data });
        let loranid = false;
        let trotamundosid = false;
        data[0].planes.forEach((item) => {
          if (item.slug === 'loran') {
            loranid = parseInt(item.id);
          }
          if (item.slug === 'trotamundos') {
            trotamundosid = parseInt(item.id);
          }
        });

        this.props.setIdPlanes(loranid, trotamundosid);

        if (this.state.current === 'edades' || this.state.current === 'colecciones' || this.state.current === 'planes') {
          var folder = this.state.current;
          var ir = this.state.categorias[0][folder];
          if (this.state.current !== 'planes') {
            ir.forEach((item) => {
              if (item.slug === this.state.localslug) {
                this.ira(parseInt(item.id), item.titulo);
              }
            });
          }else{
            var tempcat = [];
            var temptit = '';
            ir.forEach((item) => {
              if (item.slug === this.state.localslug) {
                tempcat.push();
                temptit += item.titulo+' ';
              }
              if (item.slug === this.state.subcat) {
                tempcat.push();
                temptit += item.titulo;
              }
            });
            var txtplan = this.state.subcat.charAt(0).toUpperCase() + this.state.subcat.slice(1);
            this.ira(tempcat, temptit + ' '+ txtplan );
          }
        }
      });
  }


  peticionReticula() {
    const { history, location } = this.props;
    const search = location.search;
    const params = new URLSearchParams(search);
    const ebooks = params.get('ebooks');
    const audiolibros = params.get('audiolibros');
    const loc = location.pathname.split('/');
    const folder = loc[1].toString();
    const url2 = process.env.REACT_APP_URL + 'reticula_publicaciones.php';
    axios.get(url2, {
      params: {
        categorias: JSON.stringify(this.props.searchState.search.catIds),
        parametros: JSON.stringify(this.props.searchState.search.parametros),
        ebooks: ebooks,
        audiolibros: audiolibros,
        folder: folder,
        from: 'nav'
      }
    }
    ).then(response => response.data)
      .then((data) => {
        this.props.updateTotales(data[0].totales, data[0].conteo, data[0].palabras);
      })
  }

  componentDidMount() {
    const { history, location } = this.props;
    const thisRedux = this.props.searchState.search;
    const search = location.search;
    const loc = location.pathname.split('/');
    const folder = loc[1].toString();
    const params = new URLSearchParams(search);
    const getebooks = params.get('ebooks');
    const getaudiolibros = params.get('audiolibros');
    const getparametros = params.get('parametros');
    const getcats = '[' + params.get('cats') + ']';
    const stateCats = JSON.stringify(thisRedux.catIds);
    this.cambiarURL();
    if ((getcats !== stateCats || getparametros !== thisRedux.parametros || thisRedux.ebooks !== getebooks || thisRedux.audiolibros !== getaudiolibros) && (folder === 'busqueda')) {
      this.props.overwriteCats(JSON.parse(getcats));
      this.props.sendParameters(getparametros);
      this.props.setEbooks(getebooks);
      this.props.setAudiolibros(getaudiolibros);
      this.peticionAxios();//aquí a veces descontrola la petición

    } else if (folder === 'edades' || folder === 'colecciones' || folder === 'planes') {
      if (getebooks !== null && thisRedux.ebooks !== getebooks) {
        this.props.setEbooks(getebooks);
      } else {
        this.peticionAxios();
      }
    } else {
      this.peticionAxios();
      this.peticionReticula();

    }
    if (folder === 'edades' || folder === 'colecciones' || folder === 'planes') {
      //this.setState({ current: folder, localslug: loc[2].toString() });
      this.cambiarURL();
    } else {
      this.setState({ current: 'home', localslug: null });

    }
  }

  componentDidUpdate(prevProps) {
    const { history, location } = this.props;
    const prevRedux = prevProps.searchState.search;
    const thisRedux = this.props.searchState.search;
    const { submitsearch } = this.state;
    const search = location.search;
    const params = new URLSearchParams(search);
    const newSearch = params;
    const loc = location.pathname.split('/');
    const folder = loc[1].toString();
    const getebooks = params.get('ebooks');
    const getparametros = params.get('parametros');
    const getcats = '[' + params.get('cats') + ']';
    const audiolibros = params.get('audiolibros');

    if ((thisRedux.catIds !== prevRedux.catIds || thisRedux.parametros !== prevRedux.parametros || thisRedux.ebooks !== prevRedux.ebooks || thisRedux.audiolibros !== prevRedux.audiolibros)) {
      //this.peticionAxios();
      //this.peticionReticula();

      if (folder !== 'busqueda' && folder !== 'administrador' && submitsearch === true) {
        params.set("ebooks", thisRedux.ebooks);
        params.set("audiolibros", thisRedux.audiolibros);
        params.set("cats", thisRedux.catIds);
        params.set("parametros", thisRedux.parametros);
        history.push(`/busqueda?${newSearch}`);
        this.setState({ submitsearch: false });

      }
    }
    if (this.props.location !== prevProps.location) {
      this.cambiarURL();

    }
    if (getebooks !== null && thisRedux.ebooks !== getebooks) {
      //this.props.setEbooks(getebooks);

    }
  }


  submitForm = e => {
    e.preventDefault();
    this.props.sendParameters(e.target.elements.parametros.value);
    this.cerrar();
    this.setState({ submitsearch: true });
  };

  submitParameters = (parametros) => {
    this.props.sendParameters(parametros);
    this.cerrar();
    this.setState({ submitsearch: true });
  }

  ira(cat, texto) {
    this.props.resetSearch();
    this.props.overwriteCats(cat);
    document.getElementById("textocatego").innerHTML = texto.replace(' años', '');
    const menuToggle = document.querySelector('#hamburguesita[aria-expanded="true"]');
    if (menuToggle) {
      menuToggle.click();
    }
  }


  render() {

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const ebooks = params.get('ebooks');


    var logged = this.props.searchState.login.logged;
    var rol = this.props.searchState.login.rol;
    const current_cat = this.props.searchState.search.current_cat;
    var initllave = 1;
    function llave() {
      initllave++;
      return initllave;
    }

    return (
      (this.state.categorias !== null) ? (
        <div className={this.state.current + ' cat-' + current_cat + ((this.state.localslug !== null) ? (" cl-" + this.state.localslug) : (" plano")) + ((ebooks && this.state.current === 'edades') ? (' ebooks') : ('')) + " conNav d-flex flex-column justify-content-between"}>
          <nav className="navbar navprincipal navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <button type="button" className="abrir movil" onClick={this.abrir}><SearchIcon className="search-ico align-middle" /></button>
              <Link className="navbar-brand" to="/"><Logo className="logo" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent1.show" /></Link>
              <button id="hamburguesita" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                  <li key={'nav' + llave()} className="nav-item dropdown">
                    <NavLink activeClassName="activo" className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Edades <FlechaAbajo /></NavLink>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {this.state.categorias.map((categorias) => (
                        categorias.edades.map((item) => (
                          <li key={'nav' + llave()}><NavLink activeClassName="activo" onClick={() => this.ira(parseInt(item.id), item.titulo)} className="dropdown-item" to={"/edades/" + item.slug} >{item.titulo}</NavLink></li>
                        ))
                      ))}
                    </ul>
                  </li>

                  <li key={'nav' + llave()} className="nav-item dropdown">
                    <NavLink activeClassName="activo" className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Colecciones <FlechaAbajo /></NavLink>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {this.state.categorias.map((categorias) => (
                        categorias.colecciones.map((item) => (
                          <li key={'nav' + llave()}><NavLink activeClassName="activo" onClick={() => this.ira(parseInt(item.id), item.titulo)} className="dropdown-item" to={"/colecciones/" + item.slug}>{item.titulo.replace('<br />', ': ')}</NavLink></li>
                        ))
                      ))}
                    </ul>
                  </li>

                  {<li key={'nav' + llave()} className="nav-item dropdown">
                    <NavLink activeClassName="activo" className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Planes Lectores <FlechaAbajo /></NavLink>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {this.state.categorias.map((categorias) => (
                        (categorias.planes !== 'undefined') ? (categorias.planes.map((item) => (
                          <li key={'nav' + llave()}><NavLink activeClassName="activo" onClick={() => this.ira(parseInt(item.id), item.titulo)} className="dropdown-item" to={"/planes/" + item.slug}>{item.titulo}</NavLink></li>
                        ))) : ('')

                      ))}
                    </ul>
                  </li>}

                  <li key={'nav' + llave()} className="nav-item">
                    <NavLink activeClassName="activo" className="nav-link" to="/podcast" >Podcast</NavLink>
                  </li>

                  {<li key={'nav' + llave()} className="nav-item">
                    <NavLink activeClassName="activo" className="nav-link" to="/blog" >Blog</NavLink>
                        </li>}


                </ul>
                <ul className="navbar-nav nav-derecha d-flex align-items-start  align-items-lg-center">


                  <li key={'nav' + llave()} className=" nav-item busqueda d-flex align-items-center">
                    <button type="button" className="abrir" onClick={this.abrir}><SearchIcon className="search-ico align-middle" /></button>
                  </li>


                  {logged && rol !== 'admin' ? (
                  <Fragment>
                    <li key={ 'nav' + llave() } className="divisor"></li>
                    <li key={ 'nav' + llave() } className="nav-item">
                      <NavLink activeClassName="activo" className="nav-link" to="/perfil/colecciones"><SvgColecciones className="user-ico" /> MIS COLECCIONES</NavLink>
                    </li>
                  </Fragment>
                ) : ('')}
                <li key={ 'nav' + llave() } className="divisor"></li>
                {!logged ? (
                  <li key={ 'nav' + llave() } className="nav-item registro">
                    <button className="nav-link" data-bs-toggle="modal" data-bs-target="#registroModal">REGISTRARME</button>
                  </li>
                ) : (
                  (rol !== 'admin')&&(
                    <li key={ 'nav' + llave() } className="nav-item registro">
                    <NavLink activeClassName="activo" className="nav-link" to="/perfil" ><SvgUser className="user-ico" /> VER PERFIL</NavLink>
                  </li>
                  )
                  )}

                {(rol === 'admin')?(<NavAdmin />):(null)}
                
                <li key={ 'nav' + llave() } className="divisor"></li>
                
                {!logged ? (
                  <li key={ 'nav' + llave() } className="nav-item">
                    <button className="nav-link" data-bs-toggle="modal" data-bs-target="#inicioModal"><SvgUser className="user-ico" /> INICIAR SESIÓN</button>
                  </li>
                ) : (
                  <li key={ 'nav' + llave() } className="nav-item">
                    <button className="nav-link" onClick={() => (this.props.salir(), this.props.history.push("/"))}> Cerrar sesión</button>
                  </li>
                )}


                </ul>

              </div>
            </div>

            <div className="modal fade" id="registroModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <FormaRegistro success={false} />
                  </div>
                  <div className="modal-footer">
                    <button type="button" data-bs-toggle="modal" data-bs-target="#inicioModal">¿YA TIENES CUENTA? INICIA SESIÓN</button>
                  </div>
                </div>
              </div>
            </div>

            {<FormaInicio />}

            <div className="modal fade" id="recuperarModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <button id="closerecuperar" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <FormaRecuperar />
                  </div>
                  <div className="modal-footer">
                    <button type="button" data-bs-toggle="modal" data-bs-target="#registroModal">¿NO TIENES CUENTA? REGÍSTRATE</button>
                  </div>
                </div>
              </div>
            </div>

            <div id="marcobusqueda" className={this.state.activo + " d-flex align-items-center"}>
              <form id="formabusqueda" onSubmit={this.submitForm} autoComplete="off">
                <div>
                  <Autocomplete submit={this.submitParameters} suggestions={this.props.searchState.search.palabras} />
                </div>
              </form>
              <button type="button" className="cerrar" onClick={this.cerrar}><SvgClose /></button>
            </div>
          </nav>
          <div className="footer d-flex flex-row flex-nowrap">
            {(this.state.current === 'edades') ? (<div className="globo d-flex flex-column justify-content-center align-items-center "><span id="textocatego" className={"catego " + (this.state.localslug === 'docentes' ? ('docentes') : (''))}></span>{this.state.localslug === 'docentes' ? ('') : (<span className="txtanios">años</span>)}</div>) : (<div className="globotit d-flex flex-column justify-content-center "><span id="textocatego" className={"catego " + (this.state.localslug === 'docentes' ? ('docentes') : (''))}></span></div>)}

            <div className="numero">{this.props.searchState.search.totales} Libros</div>
          </div>
        </div>) : ('nullo')
    );
  }
}


const mapStateToProps = state => ({
  searchState: state
})


const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    //entrar: (user) => dispatch({ type: 'LOG_IN', user: user }),
    salir: () => dispatch({ type: 'LOG_OUT' }),
    updateTotales: (totales, conteo, palabras, mensaje) => dispatch({ type: 'UPDATE_TOTALES', totales: totales, conteo: conteo, palabras: palabras, mensaje: mensaje }),
    sendParameters: (parametros) => dispatch({ type: 'SEND_PARAMETERS', parametros: parametros }),
    overwriteCats: (cat) => dispatch({ type: 'OVERWRITE_CATS', cat: cat }),
    setEbooks: (ebooks) => dispatch({ type: 'SET_EBOOKS', ebooks: ebooks }),
    setAudiolibros: (audiolibros) => dispatch({ type: 'SET_AUDIOLIBROS', audiolibros: audiolibros }),
    setIdPlanes: (loran, trotamundos) => dispatch({ type: 'SET_IDS_PLANES', loran: loran, trotamundos: trotamundos }),
    resetSearch: () => dispatch({ type: 'RESET_SEARCH' }),
    dispatch,
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav));