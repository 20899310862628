import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import LoopIcon from '@mui/icons-material/Loop';
import axios from 'axios';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FormaAddPublicacion from "./formaAddPublicacion";
import SendExcel from "./SendExcel";
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import AodIcon from '@mui/icons-material/Aod';
import HeadphonesIcon from '@mui/icons-material/Headphones';

class AdminReticula extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props)

        this.state = {
            libros: [],
            cargado: false,
            portadas: 20,
            editar: false,
            deshabilitados: false
        }
        this.verMAS = this.verMAS.bind(this);
    }
    verMAS() {
        this.setState({ portadas: this.state.portadas + 20 });
    }

    peticionAxios(from) {
        console.log(from);
        const thisRedux = this.props.searchState.search;
        const { history, location } = this.props;
        const search = location.search;
        const params = new URLSearchParams(search);
        const loc = location.pathname.split('/');
        const folder = loc[1].toString();
        const url = process.env.REACT_APP_URL + 'reticula_publicaciones.php';

        // Create a cancellation token source
        const cancelTokenSource = axios.CancelToken.source();

        this.setState({ cargado: false, portadas: 20 });

        // Set the cancellation token as a parameter in the GET request
        axios.get(url, {
            params: {
                categorias: JSON.stringify(thisRedux.catIds),
                parametros: JSON.stringify(thisRedux.parametros),
                ebooks: thisRedux.ebooks,
                audiolibros: thisRedux.audiolibros,
                folder: folder,
                from: from
            },
            cancelToken: cancelTokenSource.token // Pass the cancellation token
        })
            .then(response => {
                // Check if the request was cancelled
                if (axios.isCancel(response)) {
                    console.log('Request cancelled.');
                    return; // Exit the function
                }

                return response.data;
            })
            .then((data) => {
                this.setState({ libros: data, cargado: true });
                if (this.state.cargado === true) {
                    this.props.updateTotales(data[0].totales, data[0].conteo, data[0].palabras);
                }
            })
            .catch(error => {
                // Handle errors
                if (axios.isCancel(error)) {
                    console.log('Request cancelled.');
                } else {
                    console.log('Error:', error);
                }
            });

        // Cancel the previous request if this function is called again
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('Request cancelled due to new call.');
        }
        this.cancelTokenSource = cancelTokenSource; // Store the current cancellation token source
    }



    componentDidMount() {
        /*const { history, location } = this.props;
        const loc = location.pathname.split('/');
        const folder = loc[1].toString();
        const search = location.search;
        const params = new URLSearchParams(search);
        const ebooks = params.get('ebooks');
        const audiolibros = params.get('audiolibros');
        const getparametros = params.get('parametros');
        const getcats = '[' + params.get('cats') + ']';
        const stateParametros = this.props.searchState.search.parametros;
        const stateCats = JSON.stringify(this.props.searchState.search.catIds);
        if (getcats === stateCats && getparametros === stateParametros) {*/
        console.log('montando');
        const { history, location } = this.props;
        const thisRedux = this.props.searchState.search;
        const search = location.search;
        const loc = location.pathname.split('/');
        const folder = loc[1].toString();
        const params = new URLSearchParams(search);
        const getebooks = params.get('ebooks');
        const getaudiolibros = params.get('audiolibros');
        const getparametros = params.get('parametros');
        const getcats = '[' + params.get('cats') + ']';
        const stateCats = JSON.stringify(thisRedux.catIds);
        //if ((getcats === stateCats && getparametros === thisRedux.parametros && thisRedux.ebooks === getebooks && thisRedux.audiolibros === getaudiolibros) && (folder === 'publicaciones')) {
        this.peticionAxios('mount');
        //}
    }

    componentDidUpdate(prevProps) {
        const prevRedux = prevProps.searchState.search;
        const thisRedux = this.props.searchState.search;
        const continuar = false;
        const { history, location } = this.props;
        const search = location.search;
        const loc = location.pathname.split('/');
        const folder = loc[1].toString();
        const params = new URLSearchParams(search);
        const getebooks = params.get('ebooks');
        const getaudiolibros = params.get('audiolibros');
        const getparametros = params.get('parametros');
        const getcats = '[' + params.get('cats') + ']';
        if ((thisRedux.catIds !== prevRedux.catIds || thisRedux.parametros !== prevRedux.parametros || thisRedux.ebooks !== prevRedux.ebooks || thisRedux.audiolibros !== prevRedux.audiolibros)) {
            this.peticionAxios('update');
        }
    }

    deleteSAP(sap) {
        const confirmDelete = window.confirm('Está seguro que desea borrar la publicación SAP ' + sap);
        if (!confirmDelete) {
            return; // Cancel the deletion if user cancels the confirmation
        }

        let formData = new FormData();
        formData.append('sap', sap);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'admin/deleteSAP.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.peticionAxios();
                toast.success("El SAP " + sap + " ha sido eliminado correctamente", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1500,
                    hideProgressBar: true
                });
            })
            .catch(function (response) {
                // Handle error
                console.log(response);
            });
    }


    /*armarURLvars() {
        var loc = this.props.location.pathname.split('/');
        var folder = loc[1].toString();
        if (folder === "busqueda") {
            var cats = this.props.searchState.search.catIds;
            var search = this.props.searchState.search.parametros;
            var url = '';

            if (search.length > 0) {
                url += '?parametros=' + JSON.stringify(search) + '';
            }

            if (url.length > 1) {
                url += '&';
            } else {
                url += '?';
            }

            if (cats.length > 0) {
                url += 'cats=' + JSON.stringify(cats) + '';
            }

            var loc = this.props.location.pathname.split('/');
            var folder = loc[1].toString();

            if (folder === 'busqueda') {
                window.history.pushState("", "", url);
            } else {
                window.history.pushState("", "", "");
            }
        }
        //this.props.history.push("/busqueda" + url);

    }*/


    render() {
        const { items } = this.props;
        let delay_index = -1;
        var restantes = this.props.searchState.search.totales - this.state.portadas;
        return (
            <div className="reticula admin">
                <ToastContainer />
                <div className="seleccionar">
                    <button type="button" onClick={() => this.setState({ editar: false })} data-bs-toggle="modal" data-bs-target="#addPublicacion"><MenuBookIcon /> Subir Libro</button>
                    <SendExcel />
                    {/*<button type="button"><HideSourceIcon /> Mostrar deshabilitados</button>*/}
                </div>
                <div className="row grid" >

                    {
                        (this.state.cargado) ? (
                            (this.state.libros.length > 0) ? (
                                this.state.libros.map((pubs) => (
                                    pubs.libros.map((libro, index) => {

                                        delay_index += 1;
                                        const delay = 1000 + Math.max(0, delay_index * 100);
                                        if (delay_index < this.state.portadas)

                                            return (


                                                <div className="publicacion" key={libro.sap}>
                                                    <Link to={"/publicacion/" + libro.sap}>
                                                        <div className="iconos">
                                                            {((libro.ebook) && (((libro.ebook.length > 1) && (<div className="ebook"><AodIcon /></div>))))}
                                                            {((libro.audiolibro) && (((libro.audiolibro.length > 1) && (<div className="audio"><HeadphonesIcon /></div>))))}
                                                            {((libro.novedad === 1) && (<div className="novedad" />))}
                                                        </div>
                                                        {((libro.novedad === 1) && (<div className="novedad" />))}
                                                        <img
                                                            key={libro.sap} // Add key prop here
                                                            className="portada"
                                                            srcSet={
                                                                "/uploads/portadas/" +
                                                                libro.sap +
                                                                "_th.jpg?cache=" + Math.random() + // Add random query parameter
                                                                " 1x, /uploads/portadas/" +
                                                                libro.sap +
                                                                "_th@2x.jpg?cache=" + Math.random() + // Add random query parameter
                                                                " 2x"
                                                            }
                                                        />
                                                    </Link>
                                                    <div className="datosThumb">
                                                        {(libro.nivel !== '0' && libro.nivel !== '' && libro.nivel !== null) && (<div className="nivel">Nivel {libro.nivel}</div>)}
                                                        <div className="titulo">{libro.titulo}</div>
                                                        <div className="autor">{libro.autores}</div>
                                                        <div className="editar">
                                                            <button className="btn btn-secondary btn-sm" onClick={() => this.setState({ editar: libro.sap })} data-bs-toggle="modal" data-bs-target="#addPublicacion">Editar</button>
                                                        </div>
                                                        <div className="editar">
                                                            <button className="btn" onClick={() => this.deleteSAP(libro.sap)}><DeleteIcon /></button>
                                                        </div>
                                                    </div>

                                                </div>


                                            )

                                        //}
                                    })
                                ))
                            ) : (
                                <div className="col col-lg-equal d-flex flex-column justify-content-center align-items-center">
                                    <h1>La búsqueda no ha arrojado resultados.</h1><p>Por favor amplíe sus criterios de búsqueda</p>
                                </div>
                            )
                        ) : (

                            <div className="col col-lg-equal d-flex flex-column justify-content-center align-items-center">
                                <LoopIcon className="spinner" />
                                <p>Cargando</p>
                            </div>
                        )

                    }

                </div>

                <div className={"row " + ((restantes < 1) && (' restantesoculto'))}>
                    <button type="button" onClick={() => this.verMAS()} className="vermas">VER MÁS</button>
                    <span className="restantes">({restantes + " restantes"})</span>
                </div>

                <div className="modal fade" id="addPublicacion" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button id="cerrareditar" type="button" onClick={() => (this.setState({ editar: false }), this.peticionAxios())} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <FormaAddPublicacion editar={this.state.editar} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    searchState: state
})

const mapDispatchToProps = (dispatch) => {
    return {
        updateTotales: (totales, conteo, palabras, mensaje) => dispatch({ type: 'UPDATE_TOTALES', totales: totales, conteo: conteo, palabras: palabras, mensaje: mensaje }),
        sendParameters: (parametros) => dispatch({ type: 'SEND_PARAMETERS', parametros: parametros }),
        dispatch,
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminReticula));