import React, { Component } from "react";
import {
    BrowserRouter as Router,
    useParams
  } from "react-router-dom";
  import ReticulaRelacionados from '../components/ReticulaRelacionados';

  function Variables(){
    let params = useParams();
    var texto = '';
    
    if (params.sap !== undefined){
        texto += "Publicación es es: " +params.sap+" / ";
    }
    return (
        <ReticulaRelacionados sap={params.sap} />
      );
  }



class Relacionados extends Component {
  render() {
    //var params = <Variables />;
    return (
      <div id="contreticularelacionados">
        <Variables />
      </div>
    );
  }
}

export default Relacionados;