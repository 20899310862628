import React, { Component } from "react";
import { ReactComponent as Logo } from '../img/logo.svg';
import { withRouter } from "react-router";
import { FormErrors } from '../components/FormErrors.js';
import axios from 'axios';
import {Link} from "react-router-dom";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      idusuario: false,
      password: '',
      password2: '',
      formErrors: { password2: '', password: '' },
      passwordValid: false,
      password2Valid: false,
      formValid: false
    };
  }
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      { [name]: value },
      () => { this.validateField(name, value) });
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let password2Valid = this.state.password2Valid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case 'password2':
        password2Valid = value === this.state.password;
        fieldValidationErrors.password2 = password2Valid ? '' : 'Las contraseñas deben de ser idénticas';
        break;
      case 'password':
        passwordValid = value.length >= 8;
        fieldValidationErrors.password = passwordValid ? '' : 'La contraseña debe ser mayor a 8 caracteres';
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      password2Valid: password2Valid,
      passwordValid: passwordValid
    }, this.validateForm);
  }
  validateForm() {
    this.setState({ formValid: this.state.password2Valid && this.state.passwordValid });
  }
  errorClass(error) {
    return (error.length === 0 ? '' : 'has-error');
  }
  handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    let formData = new FormData();
    formData.append('password', this.state.password)
    formData.append('token', this.props.match.params.token)
    formData.append('id', this.state.idusuario)

    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'password.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        this.setState({ success: data[0].resultados });
        if (this.state.success === 1) {
          this.redirigir();
        }
      })
      .catch(function (response) {
        //handle error

        console.log(response)
      });
  }
  peticionAxios() {
    let formData = new FormData();
    formData.append('token', this.props.match.params.token);
    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'token.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        if(data[0].resultados === 1){
          this.setState({ success: data[0].resultados, idusuario: data[0].idusuario });
        }else{
          this.setState({ success: data[0].resultados });
        }
        
      })
      .catch(function (response) {
        //handle error

        console.log(response)
      });
  }
  componentDidMount() {
    this.peticionAxios();
  }
  redirigir() {
    let counter = 6
    var tt = setInterval(function() {
      counter =  counter - 1;
      document.getElementById('redirigir').innerHTML = 'Ha cambiado su contraseña exitosamente, esta ventana se cerrará en :' + counter;
      if (counter === 0) {
        clearInterval(tt);
        window.location = 'https://webapp.smdeveloper.com.mx/' //redirect here
      }
    }, 1000);
  };
  render() {
    //const token = this.props.match.params.token;
    return (
        <div id="token" className=" d-flex flex-column ">
            <Link to="/"><Logo className="logo" /></Link>
          {(this.state.success === 1 && this.state.idusuario) ? (
          <div>
          <form onSubmit={e => this.handleFormSubmit(e)}>
            <h1>Cambiar contraseña</h1>
            <h2 id="redirigir"> </h2>
            <div className="mb-3">
              <input className={`form-control ${this.errorClass(this.state.formErrors.password)}`} name="password" type="password" value={this.state.password} onChange={(event) => this.handleUserInput(event)} placeholder="Contraseña" /> {(this.state.success === 0) && (<p className="has-error">Error al actualizar, por favor intente de nuevo.</p>)}
              <input className={`form-control ${this.errorClass(this.state.formErrors.password)}`} name="password2" type="password" value={this.state.password2} onChange={(event) => this.handleUserInput(event)} placeholder="RepetirContraseña" /> 
            </div>
            <button className="rojo" type="submit" disabled={!this.state.formValid}>CAMBIAR CONTRASEÑA</button>
            <FormErrors formErrors={this.state.formErrors} />
          </form>
        </div>
            ):(
            <h2>Su token a caducado, por favor vuelva a solicitar el cambio de contraseña</h2>
            )}
        </div>
    );
  }
}

export default withRouter(Home);