import React, { useEffect, useState } from 'react';

const Politicas = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Fetch the HTML content directly
    fetch('https://admindpo.grupo-sm.com/documento?web=webapp-de-mexico&type=politica-de-privacidad&language=es')
      .then(response => response.json())
      .then(data => {
        //console.log('HTML Content:', data[0].body); // Log the HTML content to the console
        setData(data);
      })
      .catch(error => {
        console.error('Error fetching document:', error);
      });

  }, []); // Empty dependency array ensures the effect runs once after the initial render

  return (
    <div>
      {data ? (
        <div className="container-xxl politicas">
        <div className="row">
          <div className="col">
            <h2>POLITICA DE PRIVACIDAD.</h2>
          {/* Render data as HTML without sanitization (potentially dangerous) */}
          <div dangerouslySetInnerHTML={{ __html: data[0].body }}></div>
        </div>
        </div>
        </div>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default Politicas;
