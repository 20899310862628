import React, { useState, useEffect } from "react";
import axios from 'axios';
import {Link} from "react-router-dom";

const ArticuloContainer = ({ articleid = false, publico = false }) => {
  const [autor, setAutor] = useState("");
  const [titulo, setTitulo] = useState("");
  const [contenidos, setContenidos] = useState("");
  const [imageExists, setImageExists] = useState(false);
  const [creacion, setCreacion] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (articleid) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_URL}obtenerarticulo.php?id=${articleid}`
          );
          const { data } = response;
          setAutor(data.autor);
          setTitulo(data.titulo);
          setContenidos(data.contenidos);
          setCreacion(data.creacion);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [articleid]);

  useEffect(() => {
    const checkImage = async () => {
      const exists = await checkImageExists();
      setImageExists(exists);
    };

    checkImage();
  }, [articleid]);

  const checkImageExists = () => {
    return new Promise((resolve) => {
      if (!articleid) {
        resolve(false);
        return;
      }

      const img = new Image();
      img.src = `/uploads/imgarticulos/blog-${articleid}.jpg`;

      img.onload = () => {
        resolve(true);
      };

      img.onerror = () => {
        resolve(false);
      };
    });
  };

  const renderContent = () => {
    return { __html: contenidos };
  };

  return (
    <div className="articulo">
      <div className="cont_articulo">
        <div className="banner">
          {!articleid || !imageExists ? (
            <>
              <div className="titulobanner">
                <h1>{titulo}</h1>
                <h2>{autor}</h2>
                {(!publico) ? (<Link className="vermas" to={process.env.PUBLIC_URL + "/blog/" + articleid}>Leer más</Link>) : (<h3>{creacion}</h3>)}
              </div>
              <div className="video-container default-bckgr"></div>
            </>
          ) : (
            <>
              <div className="titulobanner">
                <h1 className="titulo">{titulo}</h1>
                <h2 className="autor">{autor}</h2>
                {(!publico) ? (<Link className="vermas" to={process.env.PUBLIC_URL + "/blog/" + articleid}>Leer más</Link>) : (<h3>{creacion}</h3>)}
              </div>
              <div className="video-container">
                <img src={`/uploads/imgarticulos/blog-${articleid}.jpg`} />
              </div>
            </>
          )}
        </div>
        <div
          className="ql-container"
          dangerouslySetInnerHTML={renderContent()}
        />
      </div>
    </div>
  );
};

export default ArticuloContainer;
