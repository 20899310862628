import React, { Component } from "react";
import { ReactComponent as SvgFlecha2 } from '../img/flecha2.svg';
import { Link } from "react-router-dom";

class NovedadesHome extends Component {
    render() {
        var titulo = this.props.titulo;
        var sap = this.props.sap;
        var autor = this.props.autor;

        return (

                <div className="novedades d-flex justify-content-center" >
                    <div>
                    <img srcSet={"/uploads/novedades/"+sap+"N.png 1x, /uploads/novedades/"+sap+"N@2x.png 2x"} />
                    </div>
                    <div className="novedad" />
                    <div className="columna d-flex align-items-end" >
                        <div className="d-block" >
                            <h5> NOVEDADES </h5>
                            <h2>{ titulo }</h2>
                            <h4>{ autor }</h4>
                            <Link className="botonmas" to={"/publicacion/" + sap} > VER MÁS < SvgFlecha2 /> </Link>
                        </div>
                    </div>
                </div>

        );
    }
}

export default NovedadesHome;