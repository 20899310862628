import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import PropTypes from "prop-types";
import CreateIcon from '@mui/icons-material/Create';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import FormaEditar from "../components/formaEditar";
import FormaRegistro from "../components/formaRegistro";
import Pager from "../components/Pager";
import DeleteIcon from '@mui/icons-material/Delete';

class AdminUsuarios extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props)

        this.state = {
            data: false,
            sortNombre: 'ASC',
            sortEmail: false,
            filterRol: false,
            filterTrabajo: false,
            showStatus: false,
            editando: false,
            pagina: 1,
            totales: 1,
        }
    }

    peticionAxios() {
        const { sortNombre, sortEmail, filterRol, filterTrabajo, showStatus, pagina } = this.state;
        let formData = new FormData();
        formData.append('nombre', sortNombre);
        formData.append('email', sortEmail);
        formData.append('rol', filterRol);
        formData.append('trabajo', filterTrabajo);
        formData.append('status', showStatus);
        formData.append('pagina', pagina);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'adminUsuarios.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ data: data.usuarios, totales: data.totalRecords });
            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }

    componentDidMount() {
        this.peticionAxios();
    }
    componentDidUpdate(prevProps, prevState) {
        const { sortNombre, sortEmail, filterRol, filterTrabajo, showStatus, pagina } = this.state;
        if (sortNombre !== prevState.sortNombre || sortEmail !== prevState.sortEmail || filterRol !== prevState.filterRol || filterTrabajo !== prevState.filterTrabajo || showStatus !== prevState.showStatus) {
            this.peticionAxios();
        }
        if (prevState.pagina !== pagina) {
            this.peticionAxios();
        }
    }

    setFiltros = (sort, value) => {
        this.setState({
            sortNombre: false,
            sortEmail: false,
            filterRol: false,
            filterTrabajo: false,
            showStatus: false
        });
        this.setState({
            [sort]: value
        })
    }
    habilitar = (iduser, valor) => {
        let formData = new FormData();
        formData.append('id', iduser);
        formData.append('status', valor);

        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'adminHabilitar.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.peticionAxios();
            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }
    onPageChange = (pagina) => {
        this.setState({ pagina: pagina });
    };

    borrarusuario(usuario) {
        const confirmar = window.confirm('¿Estás seguro/a de que deseas borrar este usuario?');
        if (confirmar) {
            let formData = new FormData();
            formData.append('id', usuario);
            axios({
                method: 'post',
                url: process.env.REACT_APP_URL + 'borrarusuario.php',
                data: formData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            })
                .then(response => response.data)
                .then((data) => {
                    this.peticionAxios();
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
        }
    }

    render() {
        const { data, sortNombre, sortEmail, filterRol, filterTrabajo, showStatus, editando, pagina, totales } = this.state;
        return (
            <div className="container-xxl perfil">
                <div className="row fondo_gris">
                    <div className="col px-2">
                        <h1>Administración de Usuarios</h1>
                    </div>
                </div>
                <div id="edicion">
                    <button type="button" onClick={() => (this.editando(false))} data-bs-toggle="modal" data-bs-target="#registrarModal">+</button>
                </div>
                <div className="row">
                    <div className="col px-2">
                        <div className="row">
                            <div className="col px-2">
                                <table className="table admin">
                                    <thead>
                                        <tr>
                                            <th scope="col" className={(sortNombre) ? ('activo') : (null)}>Nombre
                                                <button onClick={() => this.setFiltros('sortNombre', 'DESC')} className={(sortNombre === 'DESC') ? ('activo') : (null)}><KeyboardArrowDownIcon /></button>
                                                <button onClick={() => this.setFiltros('sortNombre', 'ASC')} className={(sortNombre === 'ASC') ? ('activo') : (null)}><KeyboardArrowUpIcon /></button>
                                            </th>
                                            <th scope="col" className={(sortEmail) ? ('activo') : (null)}>Email
                                                <button onClick={() => this.setFiltros('sortEmail', 'DESC')} className={(sortEmail === 'DESC') ? ('activo') : (null)}><KeyboardArrowDownIcon /></button>
                                                <button onClick={() => this.setFiltros('sortEmail', 'ASC')} className={(sortEmail === 'ASC') ? ('activo') : (null)}><KeyboardArrowUpIcon /></button>
                                            </th>
                                            <th scope="col" className={(filterRol) ? ('activo') : (null)}>
                                                <label className={(filterRol === false) ? ('activo') : (null)}><input name="rol" type='radio' checked={(filterRol === false) ? (true) : (false)} onChange={() => this.setState({ filterRol: false })} />Todo</label>
                                                <label className={(filterRol === 'usuario') ? ('activo') : (null)}><input name="rol" type='radio' checked={(filterRol === 'usuario') ? (true) : (false)} onChange={() => this.setState({ filterRol: 'usuario' })} />Usuario</label>
                                                <label className={(filterRol === 'admin') ? ('activo') : (null)}><input name="rol" type='radio' checked={(filterRol === 'admin') ? (true) : (false)} onChange={() => this.setState({ filterRol: 'admin' })} />Admin</label>
                                            </th>
                                            <th scope="col" className={(filterTrabajo) ? ('activo') : (null)}>
                                                <label className={(filterTrabajo === false) ? ('activo') : (null)}><input name="trabajo" type='radio' checked={(filterTrabajo === false) ? (true) : (false)} onChange={() => this.setState({ filterTrabajo: false })} />Todo</label>
                                                <label className={(filterTrabajo === 1) ? ('activo') : (null)}><input name="trabajo" type='radio' checked={(filterTrabajo === 1) ? (true) : (false)} onChange={() => this.setState({ filterTrabajo: 1 })} />Colegio</label>
                                                <label className={(filterTrabajo === 2) ? ('activo') : (null)}><input name="trabajo" type='radio' checked={(filterTrabajo === 2) ? (true) : (false)} onChange={() => this.setState({ filterTrabajo: 2 })} />Librería</label>

                                            </th>
                                            <th scope="col" className={(showStatus) ? ('activo') : (null)}>Ver deshabilitados
                                                <button onClick={() => ((showStatus) ? (this.setState({ showStatus: false })) : (this.setState({ showStatus: true })))} className={(showStatus) ? ('activo') : (null)}><CheckIcon /></button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="textodata">
                                        {(data) ? (
                                            data.map((usuario) => (
                                                <tr key={usuario.id}>
                                                    <td key='1'>{usuario.nombre}</td>
                                                    <td key='2'>{usuario.email}</td>
                                                    <td key='3'>{usuario.rol}</td>
                                                    <td key='4'>{(usuario.trabajo === 0) && ('No aplica')}{(usuario.trabajo === 1) && ('Colegio')}{(usuario.trabajo === 2) && ('Librería')}</td>
                                                    <td key='5'>
                                                        <button onClick={() => this.habilitar(usuario.id, (usuario.status === 1) ? (0) : (1))}>{(usuario.status === 1) ? (<PersonOffIcon />) : (<PersonIcon />)}</button> <button onClick={() => this.setState({ editando: usuario.id })} data-bs-toggle="modal" data-bs-target="#editarModal"><CreateIcon /></button>
                                                        {(this.props.searchState.login.user !== usuario.id) ? (<button onClick={() => this.borrarusuario(usuario.id)}><DeleteIcon /></button>) : ('')}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (null)
                                        }
                                    </tbody>
                                </table>
                                <Pager page={pagina} totales={totales} onPageChange={this.onPageChange} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="editarModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <button
                                id="closeeditarusuario"
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this.peticionAxios();
                                    this.setState({ editando: false });
                                }}
                            ></button>
                            <div className="modal-body">
                                <FormaEditar user={editando} />
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="registrarModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button id="closeregistrarusuario" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => (this.peticionAxios())}></button>
                            </div>
                            <div className="modal-body">
                                <FormaRegistro success={false} />
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

const mapStateToProps = state => ({
    searchState: state
})

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminUsuarios));