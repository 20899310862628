import React, { Component } from "react";
import { connect } from 'react-redux';
import Coleccion from "../components/Coleccion";
import axios from 'axios';
import FormaAgregarColeccion from "../components/formaAgregarColeccion";

class Colecciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            data: []
        };
    }
    TraerColecciones() {
        let formData = new FormData();
        formData.append('user', this.props.loggedstate.login.user);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'colecciones.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ success: data[0].resultados });
                if (this.state.success === 1) {
                    this.setState({
                        data: data
                    });
                    
                }
            })
            .catch(function (response) {
                //handle error

                console.log(response)
            });
    }
    componentDidMount() {
        this.TraerColecciones();
    }
    render() {
        return (

            <div className="container-xxl colecciones">
                <div className="row fondo_gris">
                    <div className="col px-2">
                        <h1>Mis Colecciones</h1>
                    </div>
                </div>
                <div id="edicion">
                    <button type="button" data-bs-toggle="modal" data-bs-target="#agregarcoleccionModal">+</button>
                </div>
                <div className="row">
                    <div className="col px-2">
                        {(this.state.data) ? (
                            this.state.data.map((data) => (
                                data.colecciones.map((coleccion) => (
                                    <Coleccion titulo={coleccion.nombre} coleccionid={coleccion.id} libros={data.libros} />
                                ))
                            ))
                        ) : ('')}


                    </div>
                </div>
                <div className="modal fade" id="agregarcoleccionModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button id="closeaddColeccion" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.TraerColecciones()}></button>
                            </div>
                            <div className="modal-body">
                                <FormaAgregarColeccion />
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
const mapDispatchToProps = (dispatch) => {
    return {
        updateTotales: (totales, conteo, palabras, mensaje) => dispatch({ type: 'UPDATE_TOTALES', totales: totales, conteo: conteo, palabras: palabras, mensaje: mensaje }),
        dispatch,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Colecciones);