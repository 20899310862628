import React, { Component, Fragment } from "react";
import {Link} from "react-router-dom";
import { ReactComponent as SvgFlecha2 } from '../img/flecha2.svg';

class SpotifyFrame extends Component {
  render() {
    var sap = this.props.sap;
    const iframe = '<iframe src="'+this.props.iframe+'" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>';
    return (
      <Fragment>
        <div className="spotifywrap" dangerouslySetInnerHTML={{ __html: iframe }} />
        {(sap !== false) && (
          <Link className="botonmas" to={"/podcast/" + sap}>Libros relacionados <SvgFlecha2 /></Link>
        )}
      </Fragment>

    );
  }
}

export default SpotifyFrame;