import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import portada from "../video/1920.mp4";
import titulo from "../img/titulobanner.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import {Link} from "react-router-dom";

const BannerHome = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselData, setCarouselData] = useState([]);
  const [isPaused, setIsPaused] = useState(false);

  const containerRef = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const timerRef = useRef(null);
  const inicial = [{ id: 0, titulo: "home" }];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_URL + "carruselArticulos.php"
        );
        const articles = response.data;
        setCarouselData([...inicial, ...articles]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (!isPaused) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
      }
    }, 8500);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [carouselData, isPaused]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    resetTimer();
  };

  const handleTouchStart = (event) => {
    touchStartX.current = event.touches[0].clientX;
  };

  const handleTouchEnd = (event) => {
    touchEndX.current = event.changedTouches[0].clientX;
    handleSwipe();
  };

  const handleSwipe = () => {
    const difference = touchStartX.current - touchEndX.current;
    const threshold = 50; // Minimum swipe distance required

    if (difference > threshold) {
      // Swiped left
      console.log("left");
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
      resetTimer();
    } else if (difference < -threshold) {
      // Swiped right
      console.log("right");
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex + carouselData.length - 1) % carouselData.length
      );
      resetTimer();
    }
  };

  const resetTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      if (!isPaused) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
      }
    }, 8500);
  };

  const handlePause = () => {
    setIsPaused((prevState) => !prevState);
  };

  return (
    <div
      id="bannerhome"
      className="row"
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {carouselData && carouselData.length > 0 ? (
        carouselData.map((item, index) => (
          <div
            key={item.id}
            className={`col ${index === currentIndex ? "active" : ""}`}
          >
            <div className="banner">
              {index === 0 ? (
                <>
                  <img
                    className="titulobanner"
                    src={titulo}
                    alt="Banner Title"
                  />
                  <div className="video-container">
                    <video
                      autoPlay
                      muted
                      loop
                      id="video"
                      poster="img/banner@2x.jpg"
                    >
                      <source src={portada} type="video/mp4" />
                    </video>
                  </div>
                </>
              ) : (
                <>
                  <div className="titulobanner articulos">
                    <h1>{item.titulo}</h1>
                    <h2>{item.autor}</h2>
                    <Link
                      className="vermas"
                      to={process.env.PUBLIC_URL + "blog/" + item.id}
                    >
                      Leer más
                    </Link>
                  </div>
                  <div className="video-container">
                    <img
                      src={`./uploads/imgarticulos/blog-${item.id}.jpg`}
                      alt={item.id}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        ))
      ) : (
        <div>Loading...</div>
      )}
      <div className="dot-buttons">
        {carouselData && carouselData.length > 0 ? (
          carouselData.map((item, index) => (
            <button
              key={item.id}
              className={`dot-button ${
                index === currentIndex ? "active" : ""
              }`}
              onClick={() => handleDotClick(index)}
            />
          ))
        ) : (
          <div>Loading...</div>
        )}
        <button className="pause-button" onClick={handlePause}>
        {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
      </button>
      </div>
    </div>
  );
};

export default BannerHome;
