import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import SpotifyFrame from "../components/SpotifyFrame";
import axios from 'axios';
import AodIcon from '@mui/icons-material/Aod';
import HeadphonesIcon from '@mui/icons-material/Headphones';

class ReticulaRelacionados extends Component {

    constructor(props) {
        super(props)
        this.state = {
            libros: []
        }

    }

    peticionAxios() {

        const url = process.env.REACT_APP_URL + 'relacionados.php';

        axios.get(url + '?sap=' + this.props.sap
        ).then(response => response.data)
            .then((data) => {
                this.setState({ libros: data })

            })

    }

    componentDidMount() {
        this.peticionAxios();
    }

    render() {

        return (
            <Fragment>

                <div className="row">
                    <div className="col spotify">
                        {(this.state.libros.length > 0) && (
                            <SpotifyFrame  iframe={this.state.libros[0].podcast} sap={false} />
                        )
                        }
                    </div>
                </div>

                <div className="row librosrelacionados">
                    <div className="col">
                        <h2>Libros relacionados</h2>
                    </div>
                </div>
                <div className="reticula">
                    <div className="row" >


                        {this.state.libros.map((pubs) => (
                            pubs.libros.map((libro) => (




                                <div className="col col-6 col-sm-equal col-md-equal col-lg-equal d-flex flex-column justify-content-center align-items-center" >
                                    <div className="publicacion">
                                        <Link to={"/publicacion/" + libro.sap}>
                                            {((libro.novedad === 1) && (<div className="novedad" />))}
                                            <img className="portada" srcSet={"/uploads/portadas/" + libro.sap + "_th.jpg 1x, /uploads/portadas/" + libro.sap + "_th@2x.jpg 2x"} />
                                            <div className="datosThumb">
                                                <div className="titulo">{libro.titulo}</div>
                                                <div className="autor">{libro.autores}</div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                            ))
                        ))}



                    </div>
                </div>

            </Fragment>
        );





    }
}

export default ReticulaRelacionados;