import React, { Component } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import axios from 'axios';
import PropTypes from "prop-types";
import Revisado from '@mui/icons-material/TaskAlt';
import Norevisado from '@mui/icons-material/RadioButtonUnchecked';
import MenuBookIcon from '@mui/icons-material/MenuBook';

class AdminColecciones extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props)

        this.state = {
            data: false,
            sortNombre: false,
            showRevisado: false,
            sortEnviado: 'DESC',
            sortCreado: false,
            filtrarUsuario: false,
            sortUsuario: false
        }
    }

    peticionAxios() {
        const {sortNombre, sortCreado, sortEnviado, sortUsuario, showRevisado, filtrarUsuario } = this.state;
        let formData = new FormData();
        formData.append('nombre', sortNombre);
        formData.append('revisado', showRevisado);
        formData.append('enviado', sortEnviado);
        formData.append('creado', sortCreado);
        formData.append('filtrarusuario', filtrarUsuario);
        formData.append('usuario', sortUsuario);

        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'adminColecciones.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ data: data[0] });

            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }

    componentDidMount() {
        this.peticionAxios();
    }
    componentDidUpdate(prevProps, prevState){
        const {sortNombre, sortCreado, sortEnviado, sortUsuario, showRevisado, filtrarUsuario } = this.state;
        if (sortNombre !== prevState.sortNombre || sortCreado !== prevState.sortCreado || sortEnviado!== prevState.sortEnviado || sortUsuario !== prevState.sortUsuario || showRevisado !== prevState.showRevisado || filtrarUsuario !== prevState.filtrarUsuario) {
            this.peticionAxios();
         }
    }

    setFiltros = (sort, value) => {
        this.setState({
            sortNombre: false,
            sortEnviado: false,
            sortCreado: false,
            sortUsuario: false,
            [sort]: value
        });
    }
    revisado = (idrevisado,valor) =>{
        let formData = new FormData();
        formData.append('id', idrevisado);
        formData.append('revisado',valor);

        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'adminRevisar.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.peticionAxios();
            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }
    render() {
        const { data, sortNombre, sortCreado, sortEnviado, sortUsuario, showRevisado, filtrarUsuario } = this.state;
        return (
            <div className="container-xxl perfil">
                <div className="row fondo_gris">
                    <div className="col px-2">
                        <h1>Colecciones</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col px-2">
                        <table className="admin table">
                            <thead>
                                <tr>
                                    <th scope="col" className={(sortUsuario) ? ('activo') : (null)}>Usuario
                                        <button onClick={() => this.setFiltros('sortUsuario', 'DESC')} className={(sortUsuario === 'DESC') ? ('activo') : (null)}><KeyboardArrowDownIcon /></button>
                                        <button onClick={() => this.setFiltros('sortUsuario', 'ASC')} className={(sortUsuario === 'ASC') ? ('activo') : (null)}><KeyboardArrowUpIcon /></button>
                                    </th>
                                    <th scope="col" className={(sortNombre) ? ('activo') : (null)}>Nombre
                                        <button onClick={() => this.setFiltros('sortNombre', 'DESC')} className={(sortNombre === 'DESC') ? ('activo') : (null)}><KeyboardArrowDownIcon /></button>
                                        <button onClick={() => this.setFiltros('sortNombre', 'ASC')} className={(sortNombre === 'ASC') ? ('activo') : (null)}><KeyboardArrowUpIcon /></button>
                                    </th>
                                    <th scope="col" className={(sortEnviado) ? ('activo') : (null)}>Enviado
                                        <button onClick={() => this.setFiltros('sortEnviado', 'DESC')} className={(sortEnviado === 'DESC') ? ('activo') : (null)}><KeyboardArrowDownIcon /></button>
                                        <button onClick={() => this.setFiltros('sortEnviado', 'ASC')} className={(sortEnviado === 'ASC') ? ('activo') : (null)}><KeyboardArrowUpIcon /></button>
                                    </th>
                                    <th scope="col" className={(sortCreado) ? ('activo') : (null)}>Creado
                                        <button onClick={() => this.setFiltros('sortCreado', 'DESC')} className={(sortCreado === 'DESC') ? ('activo') : (null)}><KeyboardArrowDownIcon /></button>
                                        <button onClick={() => this.setFiltros('sortCreado', 'ASC')} className={(sortCreado === 'ASC') ? ('activo') : (null)}><KeyboardArrowUpIcon /></button>
                                    </th>
                                    <th scope="col" className={(showRevisado) ? ('activo') : (null)}>Revisar
                                        <button onClick={() => ((showRevisado) ? (this.setState({ showRevisado: false })) : (this.setState({ showRevisado: true })))} className={(showRevisado) ? ('activo') : (null)}><CheckIcon /> ({(!showRevisado)?('ver'):('ocultar')} revisados)</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody  className="textodata">
                                    {   (data)?(
                                        data.map((coleccion) => (
                                            <tr key={coleccion.id}>
                                                <td key='1'>{coleccion.email}</td>
                                                <td key='2'><Link className="existe" to={"/administrador/colecciones/" + coleccion.id}><MenuBookIcon /> <i>{coleccion.nombre}</i></Link></td>
                                                <td key='3'>{coleccion.enviado}</td>
                                                <td key='4'>{coleccion.creado}</td>
                                                <td key='5'>{(coleccion.revisado === 0)?(<button onClick={() => this.revisado(coleccion.id, 1)}><Norevisado /></button>):(<button onClick={() => this.revisado(coleccion.id, 0)}><Revisado /></button>)}</td>
                                            </tr>
                                        ))
                                    ):(null)
                                    }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    searchState: state
})

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminColecciones));