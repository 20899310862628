import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import LoopIcon from '@mui/icons-material/Loop';
import axios, { CancelToken, isCancel } from 'axios';
import AodIcon from '@mui/icons-material/Aod';
import HeadphonesIcon from '@mui/icons-material/Headphones';


class Reticula extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props)

        this.state = {
            libros: [],
            cargado: false,
            portadas: 20
        }
        this.verMAS = this.verMAS.bind(this);
    }
    verMAS() {
        this.setState({ portadas: this.state.portadas + 20 });
    }

    peticionAxios(from) {
        //console.log(from);
        const thisRedux = this.props.searchState.search;
        const { history, location } = this.props;
        const search = location.search;
        const params = new URLSearchParams(search);
        const loc = location.pathname.split('/');
        const folder = loc[1].toString();
        const url = process.env.REACT_APP_URL + 'reticula_publicaciones.php';
      
        // Create a cancellation token source
        const cancelTokenSource = axios.CancelToken.source();
      
        this.setState({ cargado: false, portadas: 20 });
      
        // Set the cancellation token as a parameter in the GET request
        axios.get(url, {
          params: {
            categorias: JSON.stringify(thisRedux.catIds),
            parametros: JSON.stringify(thisRedux.parametros),
            ebooks: thisRedux.ebooks,
            audiolibros: thisRedux.audiolibros,
            folder: folder,
            from: from
          },
          cancelToken: cancelTokenSource.token // Pass the cancellation token
        })
          .then(response => {
            // Check if the request was cancelled
            if (axios.isCancel(response)) {
              console.log('Request cancelled.');
              return; // Exit the function
            }
      
            return response.data;
          })
          .then((data) => {
            this.setState({ libros: data, cargado: true });
            if (this.state.cargado === true) {
              this.props.updateTotales(data[0].totales, data[0].conteo, data[0].palabras);
            }
          })
          .catch(error => {
            // Handle errors
            if (axios.isCancel(error)) {
              console.log('Request cancelled.');
            } else {
              console.log('Error:', error);
            }
          });
      
        // Cancel the previous request if this function is called again
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel('Request cancelled due to new call.');
        }
        this.cancelTokenSource = cancelTokenSource; // Store the current cancellation token source
      }
      

    /*peticionAxios(from) {
        console.log(from);
        const thisRedux = this.props.searchState.search;
        const { history, location } = this.props;
        const search = location.search;
        const params = new URLSearchParams(search);
        const loc = location.pathname.split('/');
        const folder = loc[1].toString();
        const url = process.env.REACT_APP_URL + 'reticula_publicaciones.php';
        this.setState({ cargado: false, portadas: 20 });
        axios.get(url, {
            params: {
                categorias: JSON.stringify(thisRedux.catIds),
                parametros: JSON.stringify(thisRedux.parametros),
                ebooks: thisRedux.ebooks,
                audiolibros: thisRedux.audiolibros,
                folder: folder,
                from:from
            }
        }).then(response => response.data)
            .then((data) => {
                this.setState({ libros: data, cargado: true });
                if (this.state.cargado === true) {
                    this.props.updateTotales(data[0].totales, data[0].conteo, data[0].palabras);
                }
            })
    }*/

    componentDidMount() {

        const { history, location } = this.props;
        const thisRedux = this.props.searchState.search;
        const search = location.search;
        const loc = location.pathname.split('/');
        const folder = loc[1].toString();
        const params = new URLSearchParams(search);
        const getebooks = params.get('ebooks');
        const getaudiolibros = params.get('audiolibros');
        const getparametros = params.get('parametros');
        const getcats = '[' + params.get('cats') + ']';
        const stateCats = JSON.stringify(thisRedux.catIds);
        if ((getcats === stateCats && getparametros === thisRedux.parametros && thisRedux.ebooks === getebooks && thisRedux.audiolibros === getaudiolibros) && (folder === 'busqueda')) {
            this.peticionAxios('mount');
        }
    }

    componentDidUpdate(prevProps) {
        const prevRedux = prevProps.searchState.search;
        const thisRedux = this.props.searchState.search;
        const continuar = false;
        const { history, location } = this.props;
        const search = location.search;
        const loc = location.pathname.split('/');
        const folder = loc[1].toString();
        const params = new URLSearchParams(search);
        const getebooks = params.get('ebooks');
        const getaudiolibros = params.get('audiolibros');
        const getparametros = params.get('parametros');
        const getcats = '[' + params.get('cats') + ']';
        if ((thisRedux.catIds !== prevRedux.catIds || thisRedux.parametros !== prevRedux.parametros || thisRedux.ebooks !== prevRedux.ebooks || thisRedux.audiolibros !== prevRedux.audiolibros)) {
            this.peticionAxios('update');
        }
    }

    render() {
        const { items, searchState } = this.props;
        const { portadas, cargado, libros } = this.state;
        let delay_index = -1;
        var restantes = searchState.search.totales - portadas;
        return (
            <div className="reticula">
                <div className="row" >
                    {
                        (cargado) ? (
                            (libros !== []) ? (
                                libros.map((pubs) => (
                                    ((pubs.libros !== []) ? (pubs.libros.map((libro, index) => {
                                        delay_index += 1;
                                        const delay = 1000 + Math.max(0, delay_index * 100);
                                        if (delay_index < portadas) {

                                            return (

                                                <div key={'col' + delay_index} className="col col-6 col-sm-equal col-md-equal col-lg-equal d-flex flex-column justify-content-center align-items-center" >
                                                    <div className="publicacion">
                                                        <Link to={"/publicacion/" + libro.sap}>
                                                            <div className="iconos">
                                                                {((libro.ebook) && (((libro.ebook.length > 1) && (<div className="ebook"><AodIcon /></div>))))}
                                                                {((libro.audiolibro) && (((libro.audiolibro.length > 1) && (<div className="audio"><HeadphonesIcon /></div>))))}
                                                                {((libro.novedad === 1) && (<div className="novedad" />))}
                                                            </div>
                                                            {((libro.novedad === 1) && (<div className="novedad" />))}
                                                            <img alt="" className="portada" srcSet={"/uploads/portadas/" + libro.sap + "_th.jpg 1x, /uploads/portadas/" + libro.sap + "_th@2x.jpg 2x"} />
                                                            <div className="datosThumb">
                                                                {(libro.nivel !== '0' && libro.nivel !== '' && libro.nivel !== null) && (<div className="nivel">Nivel {libro.nivel}</div>)}
                                                                <div className="titulo">{libro.titulo}</div>
                                                                <div className="autor">{libro.autores}</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })) : (
                                        <div className="col col-lg-equal d-flex flex-column justify-content-center align-items-center"><h1>La búsqueda no ha arrojado resultados.</h1><p>Por favor amplíe sus criterios de búsqueda</p></div>
                                    ))
                                ))
                            ) : (
                                <div className="col col-lg-equal d-flex flex-column justify-content-center align-items-center">
                                    <h1>La búsqueda no ha arrojado resultados.</h1><p>Por favor amplíe sus criterios de búsqueda</p>
                                </div>
                            )
                        ) : (

                            <div className="col col-lg-equal d-flex flex-column justify-content-center align-items-center">
                                <LoopIcon className="spinner" />
                                <p>Cargando</p>
                            </div>
                        )
                    }

                </div>

                <div className={"row " + ((restantes < 1) && (' restantesoculto'))}>
                    <button type="button" onClick={() => this.verMAS()} className="vermas">VER MÁS</button>
                    <span className="restantes">({restantes + " restantes"})</span>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    searchState: state
})

const mapDispatchToProps = (dispatch) => {
    return {
        updateTotales: (totales, conteo, palabras, mensaje) => dispatch({ type: 'UPDATE_TOTALES', totales: totales, conteo: conteo, palabras: palabras, mensaje: mensaje }),
        sendParameters: (parametros) => dispatch({ type: 'SEND_PARAMETERS', parametros: parametros }),
        overwriteCats: (cat) => dispatch({ type: 'OVERWRITE_CATS', cat: cat }),
        dispatch,
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reticula));