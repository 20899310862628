import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ArticuloContainer from "../components/ArticuloContainer";

class Articulo extends Component {
  render() {
    const { match } = this.props;
    const articleId = match.params.articulo;

    return (
      <div>
        <ArticuloContainer articleid={articleId} publico={true} />
      </div>
    );
  }
}

export default withRouter(Articulo);