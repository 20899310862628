import React, { Component } from "react";
import Filtros from "../components/Filtros";
import Reticula from "../components/Reticula";

class Catalogos extends Component {
  render() {
    return (
        <div className="container-xxl">
          <Filtros />
          <Reticula />
        </div>
    );
  }
}

export default Catalogos;