import React, { Component } from "react";
import Carousel from 'react-elastic-carousel';
import { ReactComponent as SvgFlecha1 } from '../img/flecha1.svg';
import { Link } from "react-router-dom";


class Coleccion extends Component {


  render() {
    var titulo = this.props.titulo;
    var coleccionid = this.props.coleccionid;
    var libros = this.props.libros;
    var numlibros = 0;
    return (
      <div className="row">
        <div className="col">
          <div className="carrusel">
            <div className="encabezado d-flex justify-content-between"><div className="globotit d-flex flex-column justify-content-center "><span className="catego">{titulo}</span> </div> <Link className="d-flex align-items-center" to={"/perfil/colecciones/" + coleccionid}>VER COLECCIÓN  <SvgFlecha1 /></Link></div>
            <div className="pista">
              {<Carousel itemsToShow={4.5} pagination={false}>
                {
                  libros.map((libro) => (
                    (libro.coleccion === coleccionid) ? (
                      <div key={numlibros++} className="publicacion">
                        <Link to={"/publicacion/" + libro.sap}>
                          <img className="portada" alt={libro.sap} srcSet={"/uploads/portadas/" + libro.sap + "_th.jpg 1x, /uploads/portadas/" + libro.sap + "_th@2x.jpg 2x"} />
                          <div className="titulo">{libro.titulo}</div>
                          <div className="autor">{libro.autor}</div>
                        </Link>
                      </div>
                    ) : (false)
                  ))
                }
                {(numlibros === 0) ? (
                  <div className="publicacion">
                    <h3>Colección vacía</h3>
                  </div>
                ) : (false)}
              </Carousel>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Coleccion;