import React, { Component } from "react";
import axios from 'axios';
import Pager from "../components/Pager";
import {Link} from "react-router-dom";

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lista_articulos: [],
            pagina: 1,
            totales: 1,
        };

    }
    peticionAxios() {
        const { pagina } = this.state;
        let formData = new FormData();
        formData.append('pagina', pagina);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'listarArticulos.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ lista_articulos: data.articles, totales: data.totalRecords });

            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }
    onPageChange = (pagina) => {
        this.setState({ pagina: pagina });
    };

    componentDidMount() {
        this.peticionAxios();
    }

    render() {
        const { pagina, totales, lista_articulos } = this.state;
        return (

            <div className="container-xxl">
                <div className="row podcasts articulos g-2">
                    {lista_articulos.map((articulo) => (
                        <div key={articulo.id} className="col col-12 col-sm-12 col-md-6">
                            <div className="articulo spotifywrap" style={{ backgroundImage: `url('./uploads/imgarticulos/blog-${articulo.id}.jpg')` }}>
                            <Link to={"./blog/" + articulo.id}>
                                <div>
                                    <h2>{articulo.titulo}</h2>
                                    <h3>{articulo.autor}</h3>
                                    <span className="vermas">Leer más</span>
                                </div>
                            </Link>
                            </div>
                        </div>
                    ))}
                </div>
                <Pager page={pagina} totales={totales} onPageChange={this.onPageChange} />
            </div>
        );
    }
}

export default Blog;