import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import ReCaptchaV2 from 'react-google-recaptcha';
import { FormErrors } from './FormErrors.js';
import { withRouter } from "react-router-dom";

class FormaRegistro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: this.props.success,
      token: true,
      trabajo: '',
      rol: 'usuario',
      nombre: '',
      paterno: '',
      materno: '',
      email: '',
      password: '',
      password2: '',
      formErrors: {
        nombre: '',
        paterno: '',
        materno: '',
        email: '',
        password: '',
        password2: '',
        trabajo: ''
      },
      trabajoValid: false,
      nombreValid: false,
      paternoValid: false,
      maternoValid: false,
      emailValid: false,
      passwordValid: false,
      password2Valid: false,
      formValid: false
    };
  }

  handleToken(value) {
    this.setState({ token: true });
  }

  handleExpire() {
    this.setState({ token: false });
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      { [name]: value },
      () => { this.validateField(name, value) }
    );
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let nombreValid = this.state.nombreValid;
    let paternoValid = this.state.paternoValid;
    let maternoValid = this.state.maternoValid;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let passwordValid2 = this.state.passwordValid2;
    let trabajoValid = this.state.trabajoValid;

    switch (fieldName) {
      case 'trabajo':
        trabajoValid = value.length !== 0;
        fieldValidationErrors.trabajo = trabajoValid ? '' : 'Área de trabajo debe estar seleccionado';
        break;
      case 'nombre':
        nombreValid = value.length >= 3;
        fieldValidationErrors.nombre = nombreValid ? '' : 'Nombre debe tener más de 2 caracteres';
        break;
      case 'paterno':
        paternoValid = value.length >= 3;
        fieldValidationErrors.paterno = paternoValid ? '' : 'Apellido paterno debe tener más de 2 caracteres';
        break;
      case 'materno':
        maternoValid = value.length >= 3;
        fieldValidationErrors.materno = maternoValid ? '' : 'Apellido materno debe tener más de 2 caracteres';
        break;
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Necesita ingresar un correo electrónico válido';
        break;
      case 'password':
        passwordValid = value.length >= 8;
        fieldValidationErrors.password = passwordValid ? '' : 'La contraseña debe tener al menos 8 caracteres';
        break;
      case 'password2':
        passwordValid2 = this.state.password === value;
        fieldValidationErrors.password2 = passwordValid2 ? '' : 'Las contraseñas no coinciden';
        break;
      default:
        break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      nombreValid: nombreValid,
      paternoValid: paternoValid,
      maternoValid: maternoValid,
      emailValid: emailValid,
      passwordValid: passwordValid,
      passwordValid2: passwordValid2,
      trabajoValid: trabajoValid
    }, () => {this.validateForm()});
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.emailValid &&
        this.state.passwordValid &&
        this.state.passwordValid2 &&
        this.state.paternoValid &&
        this.state.maternoValid &&
        this.state.nombreValid &&
        this.state.trabajoValid
    });
  }

  errorClass(error) {
    return (error.length === 0 ? '' : 'has-error');
  }

  handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    let formData = new FormData();
    formData.append('trabajo', this.state.trabajo)
    formData.append('nombre', this.state.nombre)
    formData.append('paterno', this.state.paterno)
    formData.append('materno', this.state.materno)
    formData.append('email', this.state.email)
    formData.append('password', this.state.password)
    formData.append('password2', this.state.password2)
    formData.append('rol', this.state.rol)

    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'registro.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        this.setState({ success: data[0].resultados });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  render() {
    return (
      <div className={`form-group `}>
        {this.state.success === false || this.state.success === 0 ? (
          <form onSubmit={e => this.handleFormSubmit(e)}>
            <h1>Registro</h1>
            <p>Bienvenido a Literatura SM, ayúdanos con la siguiente información.</p>
            <div className="mb-3">
              <label htmlFor="trabajo" className="form-label">SELECCIONA TU ÁREA DE TRABAJO</label>
              <select
                id="trabajo"
                className="form-select"
                name="trabajo"
                value={this.state.trabajo}
                onChange={(event) => this.handleUserInput(event)}
              >
                <option value="">SELECCIONA</option>
                {(this.props.loggedstate.login.rol === 'admin') && (<option value="0">No aplica</option>)}
                <option value="1">Colegio</option>
                <option value="2">Librería</option>
              </select>
              {this.state.formErrors.trabajo && (
                <p className="has-error">{this.state.formErrors.trabajo}</p>
              )}
            </div>
            <div className="mb-3">
              <input
                className={`form-control ${this.errorClass(this.state.formErrors.nombre)}`}
                name="nombre"
                value={this.state.nombre}
                onChange={(event) => this.handleUserInput(event)}
                type="text"
                placeholder="Nombre(s)"
              />
              <input
                className={`form-control ${this.errorClass(this.state.formErrors.paterno)}`}
                name="paterno"
                value={this.state.paterno}
                onChange={(event) => this.handleUserInput(event)}
                type="text"
                placeholder="Apellido Paterno"
              />
              <input
                className={`form-control ${this.errorClass(this.state.formErrors.materno)}`}
                name="materno"
                value={this.state.materno}
                onChange={(event) => this.handleUserInput(event)}
                type="text"
                placeholder="Apellido Materno"
              />
              <input
                className={`form-control ${this.errorClass(this.state.formErrors.email)}`}
                name="email"
                value={this.state.email}
                onChange={(event) => this.handleUserInput(event)}
                type="email"
                placeholder="Correo Electrónico"
              />
              {this.state.success === 0 && (<p className="has-error">Este correo ya está registrado por otro usuario</p>)}
              <input
                className={`form-control ${this.errorClass(this.state.formErrors.password)}`}
                name="password"
                value={this.state.password}
                onChange={(event) => this.handleUserInput(event)}
                type="password"
                placeholder="Contraseña"
              />
              <input
                className={`form-control ${this.errorClass(this.state.formErrors.password2)}`}
                name="password2"
                value={this.state.password2}
                onChange={(event) => this.handleUserInput(event)}
                type="password"
                placeholder="Confirmar Contraseña"
              />
            </div>
            {(this.props.loggedstate.login.rol === 'admin') ? (
                <div className="radiogroup mb-3">
                  <input type="radio" id="rol1" name="rol" value="admin" onChange={(event) => this.handleUserInput(event)} checked={this.state.rol === 'admin'} />
                  <label htmlFor="rol1">Administrador</label>
                  <input type="radio" id="rol2" name="rol" value="usuario" onChange={(event) => this.handleUserInput(event)} checked={this.state.rol === 'usuario'} />
                  <label htmlFor="rol2">Usuario</label>
                </div>
              ) : ('')}
            {/*this.state.token && (
              <div className="mb-3">
                <ReCaptchaV2
                  sitekey="6LcrVK4fAAAAAPKBb7h6HrV1M6tVlQL4CRp6Ve4B"
                  onChange={(value) => this.handleToken(value)}
                  onExpire={() => this.handleExpire()}
                />
              </div>
            )*/}
            <div className="panel panel-default">
              <FormErrors formErrors={this.state.formErrors} />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!this.state.formValid || !this.state.token}
            >
              Registrarme
            </button>
          </form>
        ) : (
          <div>
            <h1>Registro exitoso</h1>
            <p>¡Gracias por registrarte en Literatura SM!</p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedstate: state
})

const mapDispatchToProps = (dispatch) => {
  return {
      dispatch,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormaRegistro));