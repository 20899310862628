import {
    LOG_IN,
    LOG_OUT

} from "../types";

//cada reducer tiene su propio state
const initialState = {
    logged: false,
    user: false,
    email: false,
    rol: false
}

export default function(state = initialState, action){
    switch(action.type){
        case LOG_IN:
            return {
                ...state,
                logged: true,
                user: parseInt(action.userid),
                email: action.email,
                rol: action.rol
            };
        break;
        case LOG_OUT:
            return {
                ...state,
                logged: false,
                user: false,
                email: false,
                rol: false
            };
        break;
        default:
            return state;

    }
};