import React, { Component } from "react";
import axios from "axios";
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import SpotifyFrame from "../components/SpotifyFrame";


class AdminPodcasts extends Component {

    constructor(props) {
        super(props)
        this.state = {
            editando: false,
            sap: '',
            relacionados: '',
            liga: '',
            podcasts: [],
        }
    }

    componentDidMount() {
        this.peticionAxios();
    }

    peticionAxios() {
        const url = process.env.REACT_APP_URL + 'podcasts.php';
        axios.get(url
        ).then(response => response.data)
            .then((data) => {
                this.setState({ podcasts: data })
            })
    }

    borrar(id) {
        let formData = new FormData();
        formData.append('id', id);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'deletepodcast.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.peticionAxios();
            })
            .catch(function (response) {
                //handle error
                console.log(response)
        });

    }
    editando(editando) {
        const { podcasts } = this.state;
        if (editando) {
            podcasts.map((podcasts) => (
                podcasts.map((podcast) => (
                    (podcast.id === editando) ? (
                        this.setState({
                            editando: editando,
                            sap: podcast.sap,
                            relacionados: podcast.relacionados,
                            liga: podcast.liga,
                        })

                    ) : (null)
                ))))
        }else{
            this.setState({
            editando: false,
            sap: '',
            relacionados: '',
            liga: '',
            })
        }
    }
    handleFormSubmit(event) {
        const { sap, relacionados, liga, editando } = this.state;
        event.preventDefault();
        event.stopPropagation();

        let formData = new FormData();
        formData.append('id', editando);
        formData.append('sap', sap);
        formData.append('relacionados', relacionados);
        formData.append('liga', liga);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'editpodcast.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.peticionAxios();
                document.getElementById("closeeditarpodcast").click();
            })
            .catch(function (response) {
                //handle error
                console.log(response)
        });
    }

    render() {
        const { podcasts, sap, relacionados, liga, editando } = this.state;
        return (
            <div className="container-xxl perfil">
                <div className="row fondo_gris">
                    <div className="col px-2">
                        <h1>Administración de Podcasts</h1>
                    </div>
                </div>
                <div id="edicion">
                    <button type="button" onClick={() => (this.editando(false))} data-bs-toggle="modal" data-bs-target="#editarModal">+</button>
                </div>
                <div className="row">
                    <div className="col px-2">
                        <table className="table admin">
                            <thead>
                                <tr><th>SAP</th><th>Relacionados</th><th>iFrame</th><th>Acciones</th></tr>
                            </thead>
                            <tbody>
                                {podcasts.map((podcasts) => (
                                    podcasts.map((podcast) => (
                                        <tr key={podcast.id}><td>{podcast.sap}</td><td>{podcast.relacionados}</td><td><SpotifyFrame iframe={podcast.liga} sap={podcast.sap} /></td><td>
                                            <button onClick={() => this.borrar(podcast.id)}><DeleteIcon /></button> | 
                                            <button onClick={() => (this.editando(podcast.id))} data-bs-toggle="modal" data-bs-target="#editarModal"><CreateIcon /></button></td>
                                        </tr>
                                    ))
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="modal fade" id="editarModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button id="closeeditarpodcast" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => (this.setState({ editando: false }))}></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={e => this.handleFormSubmit(e)}>
                                    <label>SAP <input className='form-control' name="sap" value={sap} onChange={(e) => this.setState({ sap: e.target.value })} type="text" placeholder="SAP" /></label>
                                    <label>Relacionados <input className='form-control' name="relacionados" value={relacionados} onChange={(e) => this.setState({ relacionados: e.target.value })} type="text" placeholder="Relacionados" /></label>
                                    <label>iFrame Spotify <textarea className='form-control' name="liga" value={liga} onChange={(e) => this.setState({ liga: e.target.value })} type="text" placeholder="iFrame" /></label>
                                    <button className="rojo" type="submit">GUARDAR CAMBIOS</button>
                                </form>
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default AdminPodcasts;