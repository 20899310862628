import React, { Component } from "react";
import BannerHome from "../components/BannerHome";
import MenuCategorias from "../components/MenuCategorias";

import Carrusel from "../components/Carrusel";
import NovedadesHome from "../components/NovedadesHome";
import Filtros from "../components/Filtros";
import CarruselHome from "../components/CarruselHome";

class Home extends Component {
  render() {
    return (
        <div className="container-xxl">
          <BannerHome />
          <CarruselHome />
        </div>
    );
  }
}

export default Home;