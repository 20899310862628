import React, { Component } from "react";
import axios from "axios";

class AdminConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      saveSuccess: false // New state property
    };
  }

  componentDidMount() {
    // Fetch the initial email data from an endpoint
    axios
      .get(process.env.REACT_APP_URL + "emailcolecciones.php?action=read")
      .then((response) => {
        this.setState({ email: response.data.email });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleEmailChange = (event) => {
    const email = event.target.value;
    this.setState({ email, emailError: "", saveSuccess: false });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;

    // Validate the email format
    if (!this.validateEmail(email)) {
      this.setState({ emailError: "Formato de correo incorrecto", saveSuccess: false });
      return;
    }

    // Create form data with the email value
    const formData = new FormData();
    formData.append("email", email);

    // Update the endpoint with the new email value
    axios
      .post(process.env.REACT_APP_URL + "emailcolecciones.php?action=write", formData)
      .then((response) => {
        console.log("Email salvado exitosamente");
        this.setState({ saveSuccess: true });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ saveSuccess: false });
      });
  };

  validateEmail = (email) => {
    // Simple email validation using regular expression
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  render() {
    const { email, emailError, saveSuccess } = this.state;

    return (
      <div className="container-xxl perfil">
        <div className="row fondo_gris">
          <div className="col px-2">
            <h1>Configuración</h1>
            <h1></h1>
          </div>
        </div>
        <div className="row">
          <div className="col px-2">
            {saveSuccess && <div className="success">Email saved successfully!</div>}
            <form onSubmit={this.handleSubmit}>
              <label>
                Email para enviar colecciones:
                <input type="email" value={email} onChange={this.handleEmailChange} />
              </label>
              {emailError && <div className="error">{emailError}</div>}
              <input type="submit" value="Salvar" />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminConfig;
