import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Filtros from "../components/Filtros";
import Reticula from "../components/Reticula";

class Busqueda extends Component {
  render() {
    //const search = this.props.location.search;
    //const parametros = new URLSearchParams(search).get("parametros");
    const {parametros, totales, mensaje}= this.props.searchState.search;
    return (
      
        <div className="container-xxl">
        <div className = "row headerbusqueda">
            <div className="col">
                <div>
                    <h3>Resultados de búsqueda</h3>
                    <h1>{(parametros === 'false' || parametros === undefined || parametros === false)?(''):(parametros)} <span>({totales}) títulos</span></h1>
                    <p>{(totales < 1)?('Sin resultados, por favor cambie el criterio de búsqueda'):('')}</p>
                </div>
            </div>
        </div>
          <Filtros />
          <Reticula />
        </div>
    );
  }
}
const mapStateToProps = state => ({
  searchState: state
})

export default withRouter(connect(mapStateToProps)(Busqueda));