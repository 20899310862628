import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import 'react-quill/dist/quill.snow.css';
import AutocompleteField from "./AutocompleteField.js";

class FormaAddPublicacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //success: false,
            data: false,
            message: false,
            visor: false,
            pdf: false,
            activo: true,
            portada: false,
            sap: '',
            isbn: '',
            titulo: '',
            coleccion: false,
            autor: '',
            nacionalidadautor: false,
            ilustrador: '',
            nacionalidadilustrador: false,
            genero: [],
            temas: [],
            palabrasclave: [],
            edades: false,
            nescolar: false,
            gescolar: false,
            plan: false,
            nivelplan: false,
            formato: false,
            paginas: '',
            encuadernacion: false,
            resena: '',
            novedad: false,
            imgnovedad: false,
            ebook: '',
            audiolibro: '',
            trailer: '',
            pedagogico: 0
        };
    }

    traerCampos() {
        const url = process.env.REACT_APP_URL + 'traertaxonomias.php';
        axios.get(url, {

        }).then(response => response.data)
            .then((data) => {
                this.setState({ data: data[0] });
            })
    }
    traercampo(id, arreglo) {
        let campo = false;
        arreglo.map((taxonomia) => {
            if (taxonomia.id === id) {
                campo = taxonomia.nombretaxonomia;
            }
        })
        return campo;
    }
    traerData() {
        let formData = new FormData();
        formData.append('sap', this.props.editar);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'traerdatalibro.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                let datos = data[0].datos;
                let taxonomias = data[0].taxonomias;
                let files = data[0].files;
                this.setState({
                    portada: files.portada,
                    sap: datos.sap,
                    isbn: datos.isbn,
                    titulo: datos.titulo,
                    coleccion: taxonomias.colecciones !== undefined ? taxonomias.colecciones[0] : false,
                    autor: taxonomias.autor !== undefined ? this.traercampo(taxonomias.autor[0], this.state.data.taxonomias[13]) : false,
                    nacionalidadautor: taxonomias.pais !== undefined ? taxonomias.pais[0] : false,
                    ilustrador: (taxonomias.ilustrador ? (this.traercampo(taxonomias.ilustrador[0], this.state.data.taxonomias[14])) : ([])),
                    nacionalidadilustrador: taxonomias.pais !== undefined ? taxonomias.pais[0] : false,
                    genero: (taxonomias.genero ? (taxonomias.genero) : ([])),
                    temas: (taxonomias.tema ? (taxonomias.tema) : ([])),
                    palabrasclave: (taxonomias.palabrasclave ? (taxonomias.palabrasclave) : ([])),
                    edades: taxonomias.edades !== undefined ? taxonomias.edades[0] : false,
                    nescolar: taxonomias.nescolar !== undefined ? taxonomias.nescolar[0] : false,
                    gescolar: taxonomias.gescolar !== undefined ? taxonomias.gescolar[0] : false,
                    plan: taxonomias.plan !== undefined ? taxonomias.plan[0] : false,
                    nivelplan: datos.nivelplan,
                    formato: taxonomias.formato !== undefined ? taxonomias.formato[0] : false,
                    paginas: datos.paginas,
                    encuadernacion: taxonomias.encuadernacion !== undefined ? taxonomias.encuadernacion[0] : false,
                    resena: datos.resena,
                    novedad: (datos.novedad === 1 ? (true) : (false)),
                    imgnovedad: files.imgnovedad,
                    visor: (datos.visor === 1 ? (true) : (false)),
                    pdf: files.pdf,
                    activo: (datos.activo === 1 ? (true) : (false)),
                    ebook: datos.ebook,
                    audiolibro: datos.audiolibro,
                    trailer: datos.trailer,
                    pedagogico: datos.pedagogico
                });
            })
            .catch(function (response) {
                //handle error
            });
    }

    wipe() {
        this.setState({
            data: false,
            message: false,
            visor: false,
            pdf: false,
            activo: true,
            portada: false,
            sap: '',
            isbn: '',
            titulo: '',
            coleccion: false,
            autor: '',
            nacionalidadautor: false,
            ilustrador: '',
            nacionalidadilustrador: false,
            genero: [],
            temas: [],
            palabrasclave: [],
            edades: false,
            nescolar: false,
            gescolar: false,
            plan: false,
            nivelplan: false,
            formato: false,
            paginas: '',
            encuadernacion: false,
            resena: '',
            novedad: false,
            imgnovedad: false,
            ebook: '',
            audiolibro: '',
            trailer: '',
            pedagogico: 0


            /*visor: false,
            message: false,
            pdf: false,
            activo: true,
            portada: false,
            sap: '',
            isbn: '',
            titulo: '',
            coleccion: false,
            autor: '',
            nacionalidadautor: false,
            ilustrador: '',
            nacionalidadilustrador: false,
            genero: [],
            temas: [],
            palabrasclave: [],
            edades: false,
            nescolar: false,
            gescolar: false,
            plan: false,
            nivelplan: false,
            formato: false,
            paginas: '',
            encuadernacion: false,
            resena: '',
            novedad: false,
            imgnovedad: false,
            ebook: false,
            audiolibro: false,
            trailer: false,
            pedagogico: 0*/
        })
    }

    componentDidMount() {
        this.traerCampos();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.editar !== this.props.editar) {
            if (this.props.editar) {
                this.traerData();
            } else {
                //this.wipe();
            }
        }
       /*if (prevState.titulo !== this.state.titulo) {
            console.log(this.state.titulo);
        }*/
    }



    handleFormSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        let error = false;
        let message = [];
        //checar visor
        if (this.state.visor) {
            if (!this.state.pdf) {
                error = true;
                message.push(<span>Si activa el visor se requier un archivo pdf.</span>);
            }
        }
        if (!this.state.portada) {
            error = true;
            message.push(<span>Seleccione una portada.</span>);
        }
        if (this.state.sap === '') {
            error = true;
            message.push(<span>El SAP es obligatorio.</span>);
        }
        if (this.state.isbn === '') {
            error = true;
            message.push(<span>El ISBN es obligatorio.</span>);
        }
        if (this.state.titulo === '') {
            error = true;
            message.push(<span>El Título es obligatorio.</span>);
        }
        if (!this.state.coleccion) {
            error = true;
            message.push(<span>Seleccione una colección.</span>);
        }
        if (this.state.autor === '') {
            error = true;
            message.push(<span>Ingrese un autor.</span>);
        }
        if (!this.state.edades) {
            error = true;
            message.push(<span>Seleccione una edad.</span>);
        }
        if (!this.state.gescolar) {
            error = true;
            message.push(<span>Seleccione una Grado Escolar.</span>);
        }
        /*if (!this.state.formato) {
            error = true;
            message.push(<span>Seleccione un formato.</span>);
        }*/
        if (this.state.paginas === '') {
            error = true;
            message.push(<span>Ingrese el número de páginas.</span>);
        }
        if (!this.state.encuadernacion) {
            error = true;
            message.push(<span>Seleccione la encuadernación.</span>);
        }
        if (this.state.resena === '') {
            error = true;
            message.push(<span>Ingrese la reseña.</span>);
        }
        if (this.state.novedad) {
            if (!this.state.imgnovedad) {
                error = true;
                message.push(<span>Si activa la novedad se requiere un archivo png.</span>);
            }
        }

        if (error) {
            this.setState({ message: message });
            return;
        } else {
            this.setState({ message: false });
        }


        let formData = new FormData();
        let formWipe = new FormData();
        formData.append('visor', this.state.visor);
        formData.append('pdf', this.state.pdf);
        formData.append('activo', this.state.activo);
        formData.append('portada', this.state.portada);
        formData.append('sap', this.state.sap);
        formData.append('isbn', this.state.isbn);
        formData.append('titulo', this.state.titulo);
        formData.append('coleccion', this.state.coleccion);
        formData.append('autor', this.state.autor);
        formData.append('nacionalidadautor', this.state.nacionalidadautor);
        formData.append('nacionalidadilustrador', this.state.nacionalidadilustrador);
        formData.append('ilustrador', this.state.ilustrador);
        formData.append('genero', this.state.genero);
        formData.append('temas', this.state.temas);
        formData.append('palabrasclave', this.state.palabrasclave);
        formData.append('edades', this.state.edades);
        formData.append('nescolar', this.state.nescolar);
        formData.append('gescolar', this.state.gescolar);
        formData.append('plan', this.state.plan);
        formData.append('nivelplan', this.state.nivelplan);
        formData.append('formato', this.state.formato);
        formData.append('paginas', this.state.paginas);
        formData.append('encuadernacion', this.state.encuadernacion);
        formData.append('resena', this.state.resena);
        formData.append('novedad', this.state.novedad);
        formData.append('imgnovedad', this.state.imgnovedad);
        formData.append('ebook', this.state.ebook);
        formData.append('audiolibro', this.state.audiolibro);
        formData.append('trailer', this.state.trailer);
        formData.append('pedagogico', this.state.pedagogico);


        /*formWipe.append('sap', this.state.sap);
        formWipe.append('portada', this.state.portada);
        formWipe.append('imgnovedad', this.state.imgnovedad);
        formWipe.append('pdf', this.state.pdf);*/

        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'editar_publicacion.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        }).then(response => response.data)
            .then((data) => {
                //this.wipe();
                document.getElementById("cerrareditar").click();
            })

        /*axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'wiper.php',
            data: formWipe,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                if (data[0] === 1) {
                    axios({
                        method: 'post',
                        url: process.env.REACT_APP_URL + 'editar_publicacion.php',
                        data: formData,
                        config: { headers: { 'Content-Type': 'multipart/form-data' } }
                    })
                        .then(response => response.data)
                        .then((data) => {
                            document.getElementById("cerrareditar").click();
                        })
                }
            }
            )
            .catch(function (response) {
                //handle error
                console.log(response)
            });*/
    }

    editorText = (value) => {
        this.setState({ resena: value });
    }

    checkzize(e, size) {
        const file = e.target.files[0];
        //const reader = new FileReader();
        const estado = e.target.name;
        let ancho = 0;
        let alto = 0;
        let error = false;
        let mensaje = '';
        if (!file) {
            return;
        }
        const image = new Image();
        image.onload = (img) => {
            ancho = img.target.width;
            alto = img.target.height;

            if (estado === 'portada') {
                if (ancho !== 1500) {
                    mensaje = 'El ancho debe de ser de 1500px';
                    error = true;
                }
            }
            if (estado === 'imgnovedad') {
                if (ancho !== 1000 || alto !== 1200) {
                    mensaje = 'El ancho debe de ser de 1000px y el alto de 1200px';
                    error = true;
                }
            }

            if (!error) {
                this.setState({ [estado]: file });
            } else {
                this.setState({ [estado]: false });
            }
        }
        image.src = URL.createObjectURL(file);
    }
    multiple(id, taxonomia) {
        const estado = this.state[taxonomia];
        let filtered = [];
        if (estado.includes(id)) {
            filtered = estado;
            var index = filtered.indexOf(id);
            if (index !== -1) {
                filtered.splice(index, 1);
            }
        } else {
            filtered = estado;
            filtered.push(id);
        }
        this.setState({ [taxonomia]: filtered });
    }

    submitAutor = (parametros) => {
        this.setState({ autor: parametros });
    }

    submitIlustrador = (parametros) => {
        this.setState({ ilustrador: parametros });
    }


    render() {
        const { data, portada, imgnovedad, visor, pdf, activo, sap, novedad, genero, temas, palabrasclave, message } = this.state;
        const autores = [];
        const ilustradores = [];

        return ((data) ? (
            <div>
                <form id="formaAddPublicacion" onSubmit={e => this.handleFormSubmit(e)} >
                    <h1>Añadir Publicación</h1>
                    <div>
                        <label>{(portada) ? ((portada === true) ? (<img src={'/uploads/portadas/' + sap + '_th.jpg'} />) : (<img src={URL.createObjectURL(portada)} />)) : (null)}Portada <input name="portada" type="file" accept="image/jpeg,image/jpg" onChange={(e) => this.checkzize(e, 1500)} /><span>La imagen debe ser en formato JPG y con un ancho de 1500px</span></label>

                        <div className="radiogroup">
                            <label>{(imgnovedad) ? ((imgnovedad === true) ? (<img src={'/uploads/novedades/' + sap + 'N.png'} />) : (<img src={URL.createObjectURL(imgnovedad)} />)) : (null)}Novedad
                                <input name="novedad" type='checkbox' checked={novedad} onChange={(e) => this.setState({ novedad: e.target.checked })} />
                            </label>
                            {(novedad ? (
                                <Fragment>
                                    <input name="imgnovedad" type="file" accept="image/x-png" onChange={(e) => this.checkzize(e, 1000)} />
                                    <span>La imagen debe ser en formato PNG y de 1000px de ancho por 1200px de alto</span>
                                </Fragment>
                            ) : (null))}
                        </div>

                        <label>Número SAP<input className='form-control' name="sap" value={sap} onChange={(e) => this.setState({ sap: e.target.value })} type="text" placeholder="SAP" disabled={this.props.editar ? (true) : (false)} /></label>

                        <label>ISBN<input className={`form-control`} name="isbn" value={this.state.isbn} onChange={(e) => this.setState({ isbn: e.target.value })} type="text" placeholder="ISBN" /></label>

                        <label>Título<input className={`form-control`} name="titulo" value={this.state.titulo} onChange={(e) => this.setState({ titulo: e.target.value })} type="text" placeholder="Título" /></label>

                        <label>COLECCION:
                            <select name="coleccion" className={"form-select" + ((this.state.coleccion === 0) ? (' seleccionar0') : (''))} onChange={(e) => this.setState({ coleccion: e.target.value })}>
                                <option value='0'>Seleccionar</option>
                                {data.taxonomias[1].map((taxonomia) => {
                                    return (
                                        <option value={taxonomia.id} key={taxonomia.id} selected={(this.state.coleccion === taxonomia.id) ? (true) : (false)}> {taxonomia.nombretaxonomia.replace('<br />', ': ')}</option>
                                    )
                                })}
                            </select>
                        </label>
                        <div className="columna">
                            {
                                data.taxonomias[13].map((taxonomia) => {
                                    autores.push(taxonomia.nombretaxonomia);
                                })
                            }
                            <label>Autor <AutocompleteField submit={this.submitAutor} suggestions={autores} campo={this.state.autor} placeholder={'AUTOR'} /></label>

                            <label>Nacionalidad del Autor  <select name="nacionalidadautor" className="form-select" onChange={(e) => this.setState({ nacionalidadautor: e.target.value })}>
                                <option value='0'>Seleccionar</option>
                                {data.taxonomias[15].map((taxonomia) => {
                                    return (
                                        <option value={taxonomia.id} key={taxonomia.id} selected={(this.state.nacionalidadautor === taxonomia.id) ? (true) : (false)}>{taxonomia.nombretaxonomia}</option>
                                    )
                                })}
                            </select>
                            </label>
                        </div>
                        <div className="columna">
                            {
                                data.taxonomias[14].map((taxonomia) => {
                                    ilustradores.push(taxonomia.nombretaxonomia);
                                })
                            }
                            <label>Ilustrador <AutocompleteField submit={this.submitIlustrador} suggestions={ilustradores} campo={this.state.ilustrador} placeholder={'Ilustrador'} /></label>

                            <label>Nacionalidad del Ilustrador <select name="nacionalidadilustrador" className="form-select" onChange={(e) => this.setState({ nacionalidadilustrador: e.target.value })}>
                                <option value='0'>Seleccionar</option>
                                {data.taxonomias[15].map((taxonomia) => {
                                    return (
                                        <option value={taxonomia.id} key={taxonomia.id} selected={(this.state.nacionalidadilustrador === taxonomia.id) ? (true) : (false)}>{taxonomia.nombretaxonomia}</option>
                                    )
                                })}
                            </select>
                            </label>
                        </div>

                        <div className="radiogroup">
                            Género {
                                data.taxonomias[2].map((taxonomia) => {

                                    return (
                                        <label key={taxonomia.id}> <input onChange={() => this.multiple(taxonomia.id, 'genero')} name="genero" type='checkbox' value={taxonomia.id} checked={genero.includes(taxonomia.id)} />{taxonomia.nombretaxonomia}</label>
                                    )
                                })
                            }
                        </div>

                        <div className="radiogroup">
                            Temas {
                                data.taxonomias[3].map((taxonomia) => {

                                    return (
                                        <label key={taxonomia.id}> <input onChange={() => this.multiple(taxonomia.id, 'temas')} name="tema" type='checkbox' value={taxonomia.id} checked={temas.includes(taxonomia.id)} />{taxonomia.nombretaxonomia}</label>
                                    )
                                })
                            }
                        </div>

                        <div className="radiogroup">
                            Palabras Clave {
                                data.taxonomias[4].map((taxonomia) => {

                                    return (
                                        <label key={taxonomia.id}> <input onChange={() => this.multiple(taxonomia.id, 'palabrasclave')} name="palabrasclave" type='checkbox' value={taxonomia.id} checked={palabrasclave.includes(taxonomia.id)} />{taxonomia.nombretaxonomia}</label>
                                    )
                                })
                            }
                        </div>

                        <label>EDAD: <select name="edades" className="form-select" onChange={(e) => this.setState({ edades: e.target.value })}>
                            <option value='0'>Seleccionar</option>
                            {data.taxonomias[5].map((taxonomia) => {
                                return (
                                    <option value={taxonomia.id} key={taxonomia.id} selected={(this.state.edades === taxonomia.id) ? (true) : (false)}>{taxonomia.nombretaxonomia}</option>
                                )
                            })}
                        </select>
                        </label>

                        <label>Nivel escolar: <select name="nescolar" className="form-select" onChange={(e) => this.setState({ nescolar: e.target.value })}>
                            <option value='0'>Seleccionar</option>
                            {data.taxonomias[6].map((taxonomia) => {
                                return (
                                    <option value={taxonomia.id} key={taxonomia.id} selected={(this.state.nescolar === taxonomia.id) ? (true) : (false)}>{taxonomia.nombretaxonomia}</option>
                                )
                            })}
                        </select>
                        </label>

                        <label>Grado Escolar: <select name="gescolar" className="form-select" onChange={(e) => this.setState({ gescolar: e.target.value })}>
                            <option value='0'>Seleccionar</option>
                            {data.taxonomias[7].map((taxonomia) => {
                                return (
                                    <option value={taxonomia.id} key={taxonomia.id} selected={(this.state.gescolar === taxonomia.id) ? (true) : (false)}>{taxonomia.nombretaxonomia}</option>
                                )
                            })}
                        </select>
                        </label>

                        <label>
                            Plan Lector <select name="plan" className="form-select" onChange={(e) => this.setState({ plan: e.target.value })}>
                                <option value='0'>Ninguno</option>
                                {data.taxonomias[8].map((taxonomia) => {
                                    return (
                                        <option value={taxonomia.id} key={taxonomia.id} selected={(this.state.plan === taxonomia.id) ? (true) : (false)}>{taxonomia.nombretaxonomia}</option>
                                    )
                                })}
                            </select>
                        </label>

                        <label>Nivel Plan
                            <select name="nivelplan" className="form-select" onChange={(e) => this.setState({ nivelplan: e.target.value })}>
                                <option value='0'>Seleccionar</option>
                                <option value='1' selected={(this.state.nivelplan === 1) ? (true) : (false)}>1</option>
                                <option value='2' selected={(this.state.nivelplan === 2) ? (true) : (false)}>2</option>
                                <option value='3' selected={(this.state.nivelplan === 3) ? (true) : (false)}>3</option>
                                <option value='4' selected={(this.state.nivelplan === 4) ? (true) : (false)}>4</option>
                                <option value='5' selected={(this.state.nivelplan === 5) ? (true) : (false)}>5</option>
                            </select>
                        </label>

                        <label>Formato
                            <select name="formato" className="form-select" onChange={(e) => this.setState({ formato: e.target.value })}>
                                <option value='0'>Seleccionar</option>
                                {data.taxonomias[9].map((taxonomia) => {
                                    return (
                                        <option value={taxonomia.id} key={taxonomia.id} selected={(this.state.formato === taxonomia.id) ? (true) : (false)}>{taxonomia.nombretaxonomia}</option>
                                    )
                                })}
                            </select>
                        </label>

                        <label>Páginas<input className='form-control' name="paginas" value={this.state.paginas} onChange={(e) => this.setState({ paginas: e.target.value })} type="text" placeholder="Número de páginas" /></label>

                        <label>Encuadernación
                            <select name="encuadernacion" className="form-select" onChange={(e) => this.setState({ encuadernacion: e.target.value })}>
                                <option value='0'>Seleccionar</option>
                                {data.taxonomias[10].map((taxonomia) => {
                                    return (
                                        <option value={taxonomia.id} key={taxonomia.id} selected={(this.state.encuadernacion === taxonomia.id) ? (true) : (false)}>{taxonomia.nombretaxonomia}</option>
                                    )
                                })}
                            </select>
                        </label>
                        <label>Reseña
                            <textarea className='form-control' name="resena" value={this.state.resena} onChange={(e) => this.setState({ resena: e.target.value })} />
                        </label>
                        <label>Ebook<input className='form-control' name="ebook" value={this.state.ebook} onChange={(e) => this.setState({ ebook: e.target.value })} type="text" placeholder="URL ebook" />
                        </label>
                        <label>Audiolibro<input className='form-control' name="ebook" value={this.state.audiolibro} onChange={(e) => this.setState({ audiolibro: e.target.value })} type="text" placeholder="URL audiolibro" />
                        </label>
                        <label>Trailer<input className='form-control' name="ebook" value={this.state.trailer} onChange={(e) => this.setState({ trailer: e.target.value })} type="text" placeholder="URL trailer" />
                        </label>
                        <label>Incuir como libro pedagógico
                            <select name="nivelplan" className="form-select" onChange={(e) => this.setState({ pedagogico: e.target.value })}>
                                <option value='0' selected={(this.state.pedagogico === 0) ? (true) : (false)}>No</option>
                                <option value='1' selected={(this.state.pedagogico === 1) ? (true) : (false)}>Si</option>
                            </select>
                        </label>
                    </div>
                    {(message) ? (<div className="errores">{message}</div>) : (null)}
                    <button className="rojo" type="submit">GUARDAR CAMBIOS</button>
                </form>
            </div>
        ) : (<div>error</div>)
        )
    }
}

const mapStateToProps = state => ({
    loggedstate: state
})

export default connect(mapStateToProps)(FormaAddPublicacion);